import axios from 'axios'
import NewPasswordData from '@/types/NewPasswordData'
import ResetPasswordData from '@/types/ResetPasswordData'

class NewPasswordService {
  async getNewPassword(data: NewPasswordData) {
    return await axios.post('api/forgot-password', data)
  }
  async resetPasswordData(data: ResetPasswordData) {
    return await axios.post('api/reset-password', data)
  }
}

export const newPasswordService = new NewPasswordService()
