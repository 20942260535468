<template>
  <div class="w-full h-full md:pb-44">
    <div class="px-4 xl:px-12 relative z-10 space-y-12 mt-20">
      <div class="space-y-2">
        <div class="xl:px-10">
          <h1 class="text-xl font-medium font-netflix_medium">
            {{ $t('member_area.series_pl') }}
          </h1>
          <div class="flex flex-wrap items-center justify-center">
            <SeriesCard
              :serie="serie"
              v-for="serie in seriesData"
              :key="serie.id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import useDevice from '@/hooks/useDevice'
import SeriesPageData from '@/types/SeriesPageData'
import { seriesDataService } from '@/services/SeriesData.Service'
import { useRouter } from 'vue-router'
import SeriesCard from '@/components/SeriesCard.vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Series',
  components: { SeriesCard },
  setup() {
    const isDesktop = useDevice().isDesktop
    const seriesData = ref<SeriesPageData[]>([])
    const router = useRouter()
    const store = useStore()
    const userData = computed(() => store.state.auth.user)
    onBeforeMount(async () => {
      await seriesDataService
        .getSeriesData()
        .then((response) => {
          const userRole = userData.value.role
          if (userRole === 'user') {
            router.push({ name: 'pay_packages' })
          }
          seriesData.value = response.data.data
        })
        .catch((error) => {
          console.log(error)
          if (
            error.response.status === 403 &&
            error.response.statusText === 'Forbidden'
          ) {
            router.push({ name: 'forbidden' })
          }
        })
    })
    return {
      isDesktop,
      seriesData,
    }
  },
})
</script>

<style scoped></style>
