import {
  RouteRecordRaw,
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  NavigationGuardNext,
} from 'vue-router'

import Dashboard from '@/views/Dashboard.vue'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import auth from '@/middleware/auth'
import store from '@/store'
import ThankYouPage from '@/views/ThankYouPage.vue'
import ForbiddenPage from '@/views/ForbiddenPage.vue'
import Video from '@/views/Video.vue'
import Series from '@/views/Series.vue'
import Actors from '@/views/Actors.vue'
import Gallery from '@/views/Gallery.vue'
import SeriesImages from '@/views/SeriesImages.vue'
import SeriesVideos from '@/views/SeriesVideos.vue'
import PasswordForgotten from '@/views/PasswordForgotten.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import PaymentPackages from '@/views/PaymentPackages.vue'
import OfferPaymentPackages from '@/views/OfferPaymentPackages.vue'
import VerifyEmail from '@/views/VerifyEmail.vue'
import UserFavorites from '@/views/UserFavorites.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/new-password',
    name: 'new_password',
    component: PasswordForgotten,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/reset-password/:token',
    name: 'reset_password',
    component: ResetPassword,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/thankyou',
    name: 'thankyou',
    component: ThankYouPage,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: ForbiddenPage,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/verify-email',
    name: 'verify_email',
    component: VerifyEmail,
    meta: {
      layout: 'empty',
    },
  },
  {
    path: '/pay-packages',
    name: 'pay_packages',
    component: PaymentPackages,
    meta: {
      layout: 'empty',
      middleware: [auth],
    },
  },
  {
    path: '/offer-pay-packages',
    name: 'offer_pay_packages',
    component: OfferPaymentPackages,
    meta: {
      layout: 'empty',
      middleware: [auth],
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/actors',
    name: 'actors',
    component: Dashboard,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/video/:video_id',
    name: 'video',
    component: Video,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/series',
    name: 'series',
    component: Series,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/actors',
    name: 'actors',
    component: Actors,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/gallery/:image_set_id',
    name: 'gallery',
    component: Gallery,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/series_images/:serie_id',
    name: 'series_images',
    component: SeriesImages,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/series_videos/:serie_id',
    name: 'series_videos',
    component: SeriesVideos,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/favorite',
    name: 'user_favorites',
    component: UserFavorites,
    meta: {
      middleware: [auth],
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView()
  },
})

router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    if (!to.meta.middleware) {
      next()
    } else if (
      to.meta.middleware &&
      (to.name === 'login' || to.name === 'register')
    ) {
      next({ name: 'dashboard' })
    }
    const middleware = to.meta.middleware
    const context = {
      to,
      from,
      store,
      next,
    }

    return middleware[0]({
      ...context,
      next,
    })
  },
)

export default router
