import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "flex-row w-full md:w-3/4 content-center md:mx-auto",
  ref: "videoPlaySite"
}
const _hoisted_2 = { class: "w-60 h-full mb-5 md:mt-5" }
const _hoisted_3 = { class: "my-2.5" }
const _hoisted_4 = { class: "text-lg" }
const _hoisted_5 = { class: "md:flex w-full mt-5" }
const _hoisted_6 = { class: "md:w-1/2 w-full font-bold" }
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "mr-2" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "md:w-1/2 w-full font-bold" }
const _hoisted_11 = { class: "flex flex-row mt-2.5 md:mt-0" }
const _hoisted_12 = { class: "w-1/2 text-yellow-500" }
const _hoisted_13 = { class: "flex w-1/2 justify-end space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_IconThumbLike = _resolveComponent("IconThumbLike")!
  const _component_circle_button = _resolveComponent("circle-button")!
  const _component_IconThumbDislike = _resolveComponent("IconThumbDislike")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LoadingSpinner, {
      ref: "loadingSpinner",
      "animation-duration": 1000,
      color: "#ed64a6",
      size: 60
    }, null, 512),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "py-2 px-4 text-center bg-pink-700 rounded-md w-full text-white text-sm hover:bg-pink-800",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
        }, _toDisplayString(_ctx.$t('video_player.back_button_text')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", {
        ref: "player",
        class: "player-container",
        style: _normalizeStyle(_ctx.playerStyleSettings),
        id: "video-player"
      }, null, 4),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('video_player.duration')) + ": " + _toDisplayString(_ctx.duration) + " min", 1),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('video_player.actors')) + ": ", 1),
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actors, (actor) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "cursor-pointer text-pink-500 hover:underline",
                  key: actor.id,
                  onClick: ($event: any) => (_ctx.handleActorClick(actor.id))
                }, _toDisplayString(actor.alias), 9, _hoisted_9))
              }), 128))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, "Likes: " + _toDisplayString(_ctx.likes), 1),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_circle_button, {
                class: "p-1",
                onClick: _withModifiers(_ctx.setLike, ["stop"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconThumbLike)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_circle_button, {
                class: "p-1",
                onClick: _withModifiers(_ctx.setDislike, ["stop"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconThumbDislike)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ])
      ])
    ], 512)
  ], 64))
}