import axios from 'axios'

class PaymentPackagesService {
  async getPaymentPackages() {
    return await axios.get('api/payment_packages')
  }

  async getOfferPaymentPackages() {
    return await axios.get('api/offer_payment_packages')
  }
}

export const paymentPackagesService = new PaymentPackagesService()
