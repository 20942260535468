<template>
  <div
    class="transition-all duration-300 md:max-w-xs w-full my-3 xl:mx-3 mx-1 serie-card"
  >
    <div class="bg-color items-center w-full cursor-pointer relative">
      <Image
        :src="
          videoSet.videos_sets_preview[0].video_set_preview_image +
          '/' +
          videoSet.slug +
          '/340x227/' +
          videoSet.videos_sets_preview[0].image_name
        "
        @click="handlePlayClick"
      />
      <div
        class="transition-all absolute left-32 top-1/3 rounded-full p-4 bg-black bg-opacity-80 border border-gray-300 cursor-pointer"
        @click="handlePlayClick"
      >
        <IconPlaySolid class="text-3xl" />
      </div>
    </div>
    <div class="p-4 w-full bg-color space-y-2 rounded-b-md">
      <div class="">
        <p class="line-clamp-1">{{ title }}</p>
        <p class="text-sm text-gray-400 line-clamp-6">
          {{ description }}
        </p>
      </div>
      <div
        class="flex justify-between space-x-2 text-xs content-center text-gray-400"
      >
        <div class="text-bold">
          <div class="flex">
            <circle-button class="p-1" @click="setLike">
              <IconThumbLike class="text-3xl" />
            </circle-button>
            <circle-button class="p-1 ml-3" @click="setDislike">
              <IconThumbDislike class="text-3xl" />
            </circle-button>
            <circle-button class="p-1 ml-3" @click="setFavorites">
              <IconNoFavorite class="text-3xl" v-if="!isFavorite" />
              <IconIsFavorite class="text-3xl" v-if="isFavorite" />
            </circle-button>
          </div>
          <p class="ml-4 pt-1">{{ likes }}</p>
        </div>

        <div class="text-bold">
          <circle-button class="p-1" @click="handlePlayClick">
            <IconPlaySolid class="text-3xl" />
          </circle-button>
          <p class="pt-1">{{ duration }} min</p>
        </div>
      </div>
      <div
        class="flex justify-between space-x-2 text-xs content-center mt-1 text-gray-400"
      >
        <div class="justify-items-center">
          <IconBxCalendar class="text-3xl" />
          {{ published }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, toRefs } from 'vue'
import Image from '@/components/atoms/Image.vue'
import CircleButton from './atoms/CircleButton.vue'
import moment from 'moment'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { videosSetsService } from '@/services/VideosSets.service'
import VideoSet from '@/types/VideoSet'
import IconThumbLike from '@/components/atoms/IconThumbLike.vue'
import IconThumbDislike from '@/components/atoms/IconThumbDislike.vue'
import IconPlaySolid from '@/components/atoms/IconPlaySolid.vue'
import IconBxCalendar from '@/components/atoms/IconBxCalendar.vue'
import IconNoFavorite from '@/components/atoms/IconNoFavorite.vue'
import IconIsFavorite from '@/components/atoms/IconIsFavorite.vue'

export default defineComponent({
  name: 'SeriesVideoCard',
  props: {
    videoSet: {
      type: Object as PropType<VideoSet>,
    },
  },
  components: {
    IconIsFavorite,
    IconNoFavorite,
    IconBxCalendar,
    IconPlaySolid,
    IconThumbDislike,
    IconThumbLike,
    Image,
    CircleButton,
  },
  setup(props) {
    let { videoSet } = toRefs(props)
    const store = useStore()
    const userData = computed(() => store.state.auth.user)
    let userLanguage: string
    const title = ref<string>('')
    const description = ref<string>('')
    const content_id = ref<number>()
    const likes = ref<number>(0)
    const isFavorite = ref<boolean>(false)
    const router = useRouter()
    const duration = ref<string>('0.0')
    const published = ref<string>('')
    if (!userData.value.language.includes('-')) {
      userLanguage = userData.value.language
    } else {
      const languageSplitString = userData.value.language.split('-')
      userLanguage = languageSplitString[0]
    }
    content_id.value = videoSet.value['id']
    likes.value = videoSet.value['likes']

    published.value = moment(videoSet.value['publish_on']).format('DD.MM.YYYY')

    for (let key in videoSet.value['videos_sets_descriptions']) {
      if (
        videoSet.value['videos_sets_descriptions'][key].language ===
        userLanguage
      ) {
        title.value = videoSet.value['videos_sets_descriptions'][key].title
        description.value =
          videoSet.value['videos_sets_descriptions'][key].description
      }
    }

    if (videoSet.value['isFavorite'].length > 0) {
      isFavorite.value = videoSet.value['isFavorite'][0].isFavorite
    }

    const h = Math.floor(videoSet.value['videos_sets_content'].duration / 3600)
    const m = Math.floor(
      (videoSet.value['videos_sets_content'].duration % 3600) / 60,
    )
    const s = Math.floor(
      (videoSet.value['videos_sets_content'].duration % 3600) % 60,
    )
    const hDisplay = h > 0 ? (h < 10 ? '0' : '') + h + ':' : ''
    const mDisplay = m > 0 ? (m < 10 ? '0' : '') + m : '00'
    const sDisplay = s > 0 ? (s < 10 ? '0' : '') + s : '00'
    duration.value = hDisplay + mDisplay + ':' + sDisplay

    const setLike = async () => {
      await videosSetsService
        .sendLike(userData.value.id, content_id.value)
        .then((response) => {
          if (response.status === 201) {
            likes.value = +1
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
    const setDislike = async () => {
      await videosSetsService
        .sendDisLike(userData.value.id, content_id.value)
        .then((response) => {
          if (response.status === 204) {
            likes.value = -1
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }

    const setFavorites = async () => {
      if (!isFavorite.value) {
        await videosSetsService
          .addToFavorites(userData.value.id, content_id.value)
          .then((response) => {
            if (response.status === 201) {
              return response.data
            }
          })
          .catch((error) => {
            console.error(error)
          })
      }
      if (isFavorite.value) {
        await videosSetsService
          .removeToFavorites(userData.value.id, content_id.value)
          .then((response) => {
            if (response.status === 204) {
              return response.data
            }
          })
          .catch((error) => {
            console.error(error)
          })
      }

      isFavorite.value = !isFavorite.value
    }

    const handlePlayClick = () => {
      if (userData.value.role === 'user') {
        console.warn('Kein Pay User')
      }
      router.push({ name: 'video', params: { video_id: content_id.value } })
    }

    return {
      title,
      description,
      content_id,
      likes,
      isFavorite,
      duration,
      published,
      setLike,
      setDislike,
      setFavorites,
      handlePlayClick,
    }
  },
})
</script>

<style scoped>
.bg-color {
  background: #1e1e1e;
}

.serie-card img {
  @apply object-cover rounded-md w-full h-full;
}
</style>
