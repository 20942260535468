<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    width="1em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M16.5 17.985c0 2.442-1.14 4.199-3.007 4.199c-.975 0-1.341-.543-1.69-1.796l-.207-.772c-.101-.359-.277-.97-.527-1.83a.249.249
      0 0 0-.03-.066l-2.866-4.485a5.884 5.884 0 0 0-2.855-2.327l-.473-.181A2.75 2.75 0 0 1 3.13 7.635l.404-2.086A3.25 3.25
      0 0 1 5.95 3.011l7.628-1.87a4.75 4.75 0 0 1 5.733 3.44l1.415 5.55a3.25 3.25 0 0 1-3.15 4.053h-1.822c.496 1.632.746 2.892.746
      3.801ZM4.6 7.921a1.25 1.25 0 0 0 .78 1.405l.474.181a7.385 7.385 0 0 1 3.582 2.92l2.867 4.486c.09.14.159.293.205.454l.552
      1.92l.212.792c.14.488.21.605.22.605c.868 0 1.507-.984 1.507-2.699c0-.884-.326-2.335-.984-4.315a.75.75 0 0 1 .711-.986h2.85a1.751
      1.751 0 0 0 1.696-2.183l-1.415-5.549a3.25 3.25 0 0 0-3.923-2.354l-7.628 1.87a1.75 1.75 0 0 0-1.301 1.367L4.6 7.92Z"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'IconThumbDislike',
})
</script>

<style scoped></style>
