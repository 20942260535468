import { reactive, readonly } from 'vue'

const initialState = reactive({
  isActorsDetailModalActive: false,
  isVideoPlayer: false,
  isNewGallery: false,
  scrollTop: 0,
  actorData: null,
})

export const setActorsDetailModalActive = (isActive: boolean): void => {
  // Keep scrollTop
  if (isActive) {
    initialState.scrollTop = document.documentElement.scrollTop
  }

  initialState.isActorsDetailModalActive = isActive
}

export const setIsNewGallery = (isNewGallery: boolean): void => {
  initialState.isNewGallery = isNewGallery
}

export const setIsVideoPlayer = (isVideoPlayer: boolean): void => {
  initialState.isVideoPlayer = isVideoPlayer
}

export const setActorData = (actorId: number): void => {
  initialState.actorData = actorId
}

export const state = readonly(initialState)
