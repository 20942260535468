<template>
  <div
    ref="container"
    class="cursor-pointer actor-card relative transition-all duration-300 video-card-pt w-full my-10 mx-1 rounded-b-none md:max-w-xs xl:mx-3"
    :class="{
      'animate-card-hover': isScaled,
      'animate-card-unhover': !isScaled,
    }"
  >
    <Image
      :src="actorData.actor_preview_image"
      @click="handleActorClick(actorData.id)"
    />
    <div
      class="actor-card-bg absolute top-full w-full h-26 rounded-b-md transition-all duration-300 shadow p-3 space-y-2"
    >
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-2"></div>
      </div>
      <p class="line-clamp-1">{{ actorData.alias }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import Actors from '@/types/Actors'
import Image from '@/components/atoms/Image.vue'
import { setActorsDetailModalActive, setActorData } from '@/store/ActorModal'

export default defineComponent({
  name: 'ActorCard',
  props: {
    actorData: {
      type: Object as PropType<Actors>,
    },
  },
  components: {
    Image,
  },
  setup() {
    const isScaled = ref(false)

    const handleActorClick = (actorId: number) => {
      console.log('Clicked Actor Card ActorId: ' + actorId)
      setActorData(actorId)
      setActorsDetailModalActive(true)
    }

    return {
      isScaled,
      handleActorClick,
    }
  },
})
</script>

<style scoped>
.actor-card-bg {
  background-color: #1e1e1e;
}
.actor-card img {
  @apply object-cover rounded-md top-0 left-0 w-full h-full;
}
</style>
