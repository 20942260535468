<template>
  <img :src="src" v-lazy="src" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Image',
  props: {
    src: {
      type: String,
    },
  },
})
</script>
<style>
img[lazy='loaded'] {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: fadeIn;
}
</style>
