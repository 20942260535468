<template>
  <div
    class="bg-cover h-screen px-6"
    style="
      background-image: url('https://images.sextermedia.com/content/images/background/0x0/sextermedia_background.webp');
    "
  >
    <div class="flex pt-4 pl-4">
      <img src="../assets/sextermedia-logo.png" />
    </div>
    <div class="flex justify-center items-center h-3/4">
      <div class="p-6 max-w-sm w-full bg-black shadow-md rounded-md">
        <div class="flex justify-center items-center">
          <span class="text-white font-semibold text-2xl">Login</span>
        </div>
        <div class="flex justify-center items-center" v-if="error">
          <span class="text-red-500 font-medium">{{ error }}</span>
        </div>
        <form class="mt-4" @submit.prevent="login">
          <div class="mb-6">
            <label for="email" class="inline-block text-sm mb-2">
              {{ $t('member_form.form.label_email_text') }}:
            </label>
            <input
              type="email"
              id="email"
              class="w-full h-10 bg-gray-800 text-white px-3 rounded-sm"
              v-model="form.email"
            />
          </div>
          <div class="mb-6">
            <label for="password" class="inline-block text-sm mb-2">
              {{ $t('member_form.form.label_password_text') }}:
            </label>
            <input
              type="password"
              id="password"
              class="w-full h-10 bg-gray-800 text-white px-3 rounded-sm"
              v-model="form.password"
            />
          </div>
          <div class="flex justify-between items-center mt-4">
            <div>
              <label class="inline-flex items-center">
                <input
                  type="checkbox"
                  class="form-checkbox bg-gray-800 text-gray-800"
                />
                <span class="mx-2 text-xs">Remember me</span>
              </label>
            </div>

            <div class="text-xs">
              <router-link :to="{ name: 'new_password' }">
                Forgot your password?
              </router-link>
            </div>
          </div>

          <div class="mt-6">
            <button
              type="submit"
              class="py-2 px-4 text-center bg-pink-700 rounded-md w-full text-white text-sm hover:bg-pink-800"
            >
              {{ $t('member_form.form.login_button_text') }}
            </button>
          </div>
          <div class="mt-5">
            {{ $t('member_form.form.new_user_text') }}
            <router-link :to="{ name: 'register' }"
              >{{ $t('member_form.form.register_link_text') }}
            </router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'Login',
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      error: null,
    }
  },
  methods: {
    ...mapActions({
      loginAction: 'auth/login',
    }),
    ...mapGetters({
      loggedUserGetter: 'auth/user',
    }),
    async login() {
      await this.loginAction(this.form)
        .then(() => {
          if (!this.loggedUserGetter().language) {
            this.loggedUserGetter().language = navigator.language
          }
          const userRole = this.loggedUserGetter().role
          let hours = moment().diff(
            moment(this.loggedUserGetter().email_verified_at),
            'hours',
            true,
          )
          if (userRole === 'user' && hours < 24) {
            this.$router.push({ name: 'pay_packages' })
          }
          if (userRole === 'user' && hours > 24) {
            this.$router.push({ name: 'offer_pay_packages' })
          }
          if (
            userRole === 'pay_user' ||
            userRole === 'admin' ||
            userRole === 'super_admin'
          ) {
            this.$router.push({ name: 'dashboard' })
          }
        })
        .catch((error) => {
          console.log(error)
          this.error = error.response.data.message
        })
    },
  },
})
</script>
