import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonItem = _resolveComponent("SkeletonItem", true)!

  return (_ctx.type === 'element')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([
      !_ctx.layout.isContainer && 'animate-pulse bg-white bg-opacity-20',
      _ctx.layout.class,
    ])
      }, [
        (_ctx.layout.isContainer)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layout.children, (childItem, index) => {
                return (_openBlock(), _createBlock(_component_SkeletonItem, {
                  layout: childItem,
                  key: index
                }, null, 8, ["layout"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 2))
    : (_ctx.type === 'fragment')
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.layout.children, (childItem, index) => {
          return (_openBlock(), _createBlock(_component_SkeletonItem, {
            layout: childItem,
            key: index
          }, null, 8, ["layout"]))
        }), 128))
      : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout.type), _normalizeProps(_mergeProps({ key: 2 }, _ctx.layout.props)), null, 16))
}