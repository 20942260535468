<template>
  <div class="space-y-2">
    <h1 class="text-xl font-medium font-netflix_medium">{{ carouselName }}</h1>
    <div
      ref="container"
      class="carousel-container relative"
      :class="[childHovering ? 'animate-z-hover' : 'animate-z-unhover']"
    >
      <Swiper
        v-bind="options"
        @swiper="onReady"
        @slideChange="handleToggleButton"
      >
        <SwiperSlide v-for="item in items" :key="item.id" class="slides">
          <video-card :videoData="item" />
        </SwiperSlide>
        <template #container-end>
          <div
            class="swiper-button swiper-button-prev group cursor-pointer flex items-center justify-center"
          >
            <IconArrowRightAlt2 class="text-3xl" />
          </div>

          <div
            class="swiper-button group cursor-pointer swiper-button-next flex items-center justify-center"
          >
            <IconArrowLeftAlt2 class="text-3xl" />
          </div>
        </template>
      </Swiper>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination } from 'swiper'
import 'swiper/swiper.min.css'
import VideoCard from './VideoCard.vue'
import IconArrowRightAlt2 from '@/components/atoms/IconArrowRightAlt2.vue'
import IconArrowLeftAlt2 from '@/components/atoms/IconArrowLeftAlt2.vue'

export default defineComponent({
  name: 'VideoCarousel',
  props: {
    items: {
      type: [],
    },
    carouselName: {
      type: String,
    },
  },
  setup() {
    const container = ref(null)
    const childHovering = ref(false)
    const options = {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 5,
      modules: [Pagination],
      pagination: {
        type: 'bullets',
      },
      speed: 800,
      watchSlidesProgress: true,
      breakpoints: {
        1280: {
          slidesPerView: 6,
          slidesPerGroup: 6,
        },
        1024: {
          slidesPerView: 5,
          slidesPerGroup: 5,
        },
        768: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 3,
        },
        640: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 2,
        },
      },
    }
    const handleToggleButton = (swiper) => {
      const prevButton = container.value.querySelector('.swiper-button-prev')
      const nextButton = container.value.querySelector('.swiper-button-next')
      if (swiper.isBeginning && !swiper.params.loop) {
        prevButton.classList.add('swiper-button-disabled')
      } else {
        prevButton.classList.remove('swiper-button-disabled')
      }
      if (swiper.isEnd && !swiper.params.loop) {
        nextButton.classList.add('swiper-button-disabled')
      } else {
        nextButton.classList.remove('swiper-button-disabled')
      }
      handleSlideHover()
    }
    const listenCardHovering = () => {
      const config = {
        attributes: true,
        subtree: true,
      }
      const callback = function (mutations) {
        for (let mutation of mutations) {
          const { target } = mutation
          if (target.classList.contains('animate-card-hover')) {
            childHovering.value = true
          } else if (target.classList.contains('animate-card-unhover')) {
            childHovering.value = false
          }
        }
      }
      const observer = new MutationObserver(callback)
      observer.observe(container.value, config)
    }

    const handleSlideHover = () => {
      const slides = [
        ...container.value.querySelectorAll('.swiper-slide .video-card'),
      ]
      const firstElement = slides[0]
      //const lastElement = slides[slides.length - 1]
      if (firstElement) {
        firstElement.style.transformOrigin = 'left center'
      }
      //  lastElement.style.transformOrigin = 'right center'
    }

    const onReady = (swiper) => {
      //console.log('onRead fierd')
      const prevButton = container.value.querySelector('.swiper-button-prev')
      const nextButton = container.value.querySelector('.swiper-button-next')
      prevButton.addEventListener('click', (e) => {
        e.preventDefault()
        if (swiper.isBeginning && !swiper.params.loop) return
        swiper.slidePrev()
      })
      nextButton.addEventListener('click', (e) => {
        e.preventDefault()
        if (swiper.isEnd && !swiper.params.loop) return
        swiper.slideNext()
      })
      handleToggleButton(swiper)
      listenCardHovering()
      handleSlideHover()
    }

    return {
      options,
      container,
      onReady,
      handleToggleButton,
      childHovering,
    }
  },
  components: {
    IconArrowLeftAlt2,
    IconArrowRightAlt2,
    VideoCard,
    Swiper,
    SwiperSlide,
  },
})
</script>
<style>
.swiper-container {
  overflow: visible;
}

.swiper-button-disabled {
  display: none;
}

.swiper-container:hover .swiper-button svg,
.swiper-container:hover .swiper-pagination {
  visibility: visible;
}

.swiper-button svg {
  visibility: hidden;
}

.swiper-button-next,
.swiper-button-prev {
  @apply absolute h-full w-6 md:w-12 mt-0 top-0 bg-black bg-opacity-50;
  z-index: 2;
}

.swiper-button-prev {
  @apply -left-6 md:-left-12;
}

.swiper-button-next {
  @apply -right-6 md:-right-12;
}

.swiper-pagination {
  @apply space-x-1 w-24 h-0.5 absolute right-0 -top-4 flex invisible;
}

.swiper-pagination-bullet {
  @apply h-full  bg-pink-600 hidden md:block;
  flex: 1 1 0%;
}

.swiper-pagination-bullet-active {
  @apply bg-white;
}
</style>
