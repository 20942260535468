export const defaultLocale = 'de'

export const SUPPORTED_LOCALES = [
  {
    base: '',
    code: 'de',
    name: 'Deutsch',
    dir: 'ltr',
  },
  {
    base: '/en',
    code: 'en',
    name: 'English',
    dir: 'ltr',
  },
  {
    base: '/es',
    code: 'es',
    name: 'Espanole',
    dir: 'ltr',
  },
  {
    base: '/fr',
    code: 'fr',
    name: 'France',
    dir: 'ltr',
  },
  {
    base: '/it',
    code: 'it',
    name: 'Italia',
    dir: 'ltr',
  },
]
