<template>
  <button
    class="flex items-center space-x-3 px-6 py-2 rounded-md hover:bg-opacity-80"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Button',
  methods: {
    handleClick(event) {
      this.$emit('click', event)
    },
  },
})
</script>
