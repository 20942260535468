<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div class="w-full h-full md:pb-44">
    <div class="px-4 xl:px-12 relative z-10 space-y-12 mt-20">
      <div class="space-y-2">
        <div class="xl:px-10" v-if="favorites">
          <h1 class="text-xl font-light font-netflix_medium">
            {{ $t('member_area.user_favorites') }}
          </h1>
          <h2
            class="text-xl font-light font-netflix_medium mt-5"
            v-if="favorites.videos_sets"
          >
            {{ $t('member_area.favoriten_area_videos') }}
          </h2>
          <div
            class="flex flex-wrap items-center justify-center"
            ref="scrollComponent"
            v-if="favorites.videos_sets"
          >
            <SeriesVideoCard
              :video-set="videoSet"
              v-for="videoSet in favorites.videos_sets"
              :key="videoSet.id"
            />
          </div>
          <h2
            class="text-xl font-light font-netflix_medium mt-5"
            v-if="favorites.videos_sets"
          >
            {{ $t('member_area.favoriten_area_images') }}
          </h2>
          <div
            class="flex flex-wrap items-center justify-center"
            v-if="favorites.image_sets"
          >
            <SeriesImagesCard
              :imageSet="imageSet"
              v-for="imageSet in favorites.image_sets"
              :key="imageSet.id"
            />
          </div>
        </div>
        <div class="xl:px-10" v-if="!favorites">
          <h1 class="text-xl font-light font-netflix_medium">
            {{ $t('member_area.user_favorites') }}
          </h1>
          <div class="text-xl font-light font-netflix_medium mt-5">
            {{ $t('member_area.no_favorites') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import { userFavoritesService } from '@/services/UserFavorites.Service'
import { useStore } from 'vuex'
import UserFavoritesData from '@/types/userFvaoritesData'
import SeriesVideoCard from '@/components/SeriesVideoCard.vue'
import SeriesImagesCard from '@/components/SeriesImagesCard.vue'

export default defineComponent({
  name: 'UserFavorites',
  components: {
    LoadingSpinner,
    SeriesVideoCard,
    SeriesImagesCard,
  },
  setup() {
    const loadingSpinner = ref(null)
    const store = useStore()
    const userData = computed(() => store.state.auth.user)
    const favorites = ref<UserFavoritesData>()
    onBeforeMount(async () => {
      await getFavorites()
    })
    const getFavorites = async () => {
      await userFavoritesService
        .getUserFavorites(userData.value.id)
        .then((response) => {
          favorites.value = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
    return {
      loadingSpinner,
      favorites,
    }
  },
})
</script>

<style scoped></style>
