<template>
  <div
    class="bg-cover h-screen px-6"
    style="
      background-image: url('https://images.sextermedia.com/content/images/background/0x0/sextermedia_background.webp');
    "
  >
    <div class="flex pt-4 pl-4">
      <img src="../assets/sextermedia-logo.png" />
    </div>
    <div class="flex justify-center items-center h-3/4">
      <div class="p-6 max-w-sm w-full bg-black shadow-md rounded-md">
        <div class="flex justify-center items-center">
          <span class="text-white font-semibold text-2xl">
            {{ $t('member_form.form.reset_password_title') }}</span
          >
        </div>
        <div class="flex justify-center items-center" v-if="status_message">
          <span class="text-green-300 font-medium">{{ status_message }}</span>
        </div>
        <div class="flex justify-center items-center" v-if="error">
          <span class="text-red-500 font-medium">{{ error }}</span>
        </div>

        <form class="mt-4" @submit.prevent="newPassword" v-if="!status_message">
          <div class="mb-6">
            <label for="email" class="inline-block text-sm mb-2">
              {{ $t('member_form.form.label_email_text') }}:
            </label>
            <input
              type="email"
              id="email"
              class="w-full h-10 bg-gray-800 text-white px-3 rounded-sm"
              v-model="form.email"
            />
          </div>
          <div class="mt-6">
            <button
              type="submit"
              class="py-2 px-4 text-center bg-pink-700 rounded-md w-full text-white text-sm hover:bg-pink-800"
            >
              {{ $t('member_form.form.reset_password_button_text') }}
            </button>
          </div>
          <div class="mt-5">
            {{ $t('member_form.form.new_user_text') }}
            <router-link :to="{ name: 'register' }">{{
              $t('member_form.form.register_link_text')
            }}</router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { newPasswordService } from '@/services/NewPassword.Service'
import NewPasswordData from '@/types/NewPasswordData'
import useVuelidate from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import { computed, defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  name: 'PasswordForgotten',
  setup() {
    const status_message = ref<string>()
    const form = ref<NewPasswordData>({ email: '' })
    let error = ref<string[]>()

    const state = reactive({
      form,
    })
    const rules = computed(() => {
      return {
        form: {
          email: { required, email, $autoDirty: true },
        },
      }
    })
    const v$ = useVuelidate(rules, state)
    const newPassword = async () => {
      //error.value = []
      await newPasswordService
        .getNewPassword(form.value)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data['status'])
            status_message.value = response.data['status']
          }
        })
        .catch((error_response) => {
          const errorsObject = error_response.response.data
          error.value = Object.keys(errorsObject).map((errorIndex) => {
            return errorsObject[errorIndex]
          })
        })
    }
    return {
      form,
      newPassword,
      v$,
      error,
      status_message,
    }
  },
})
</script>

<style scoped></style>
