import axios from 'axios'

class SeriesDataService {
  async getSeriesData() {
    return await axios.get('api/series')
  }

  async getSeriesImagesSets(
    perPage: number = null,
    page: number | string | string[] = null,
    series_id: number | string | string[],
  ) {
    return axios.get(
      `api/series_images/${series_id}?per_page=${perPage}&page=${page}`,
    )
  }

  async getSeriesVideosSets(
    perPage: number = null,
    page: number | string | string[] = null,
    series_id: number | string | string[],
  ) {
    return axios.get(
      `api/series_videos/${series_id}?per_page=${perPage}&page=${page}`,
    )
  }
}

export const seriesDataService = new SeriesDataService()
