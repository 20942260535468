<template>
  <LoadingSpinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#ed64a6"
    :size="60"
  ></LoadingSpinner>
  <div
    class="flex-row w-full md:w-3/4 content-center md:mx-auto"
    ref="videoPlaySite"
  >
    <div class="w-60 h-full mb-5 md:mt-5">
      <button
        class="py-2 px-4 text-center bg-pink-700 rounded-md w-full text-white text-sm hover:bg-pink-800"
        @click="goBack"
      >
        {{ $t('video_player.back_button_text') }}
      </button>
    </div>
    <div class="my-2.5">
      <h1 class="text-lg">{{ title }}</h1>
    </div>
    <div
      ref="player"
      class="player-container"
      :style="playerStyleSettings"
      id="video-player"
    ></div>
    <div class="md:flex w-full mt-5">
      <div class="md:w-1/2 w-full font-bold">
        <span>{{ $t('video_player.duration') }}: {{ duration }} min</span>
        <div class="flex">
          <span class="mr-2">{{ $t('video_player.actors') }}: </span>
          <ul>
            <li
              class="cursor-pointer text-pink-500 hover:underline"
              v-for="actor in actors"
              :key="actor.id"
              @click="handleActorClick(actor.id)"
            >
              {{ actor.alias }}
            </li>
          </ul>
        </div>
      </div>
      <div class="md:w-1/2 w-full font-bold">
        <div class="flex flex-row mt-2.5 md:mt-0">
          <div class="w-1/2 text-yellow-500">Likes: {{ likes }}</div>
          <div class="flex w-1/2 justify-end space-x-2">
            <circle-button class="p-1" @click.stop="setLike">
              <IconThumbLike />
            </circle-button>
            <circle-button class="p-1" @click.stop="setDislike">
              <IconThumbDislike />
            </circle-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive, computed, watch } from 'vue'
import Artplayer from 'artplayer'
import VideoQuality from '@/types/videoQuality'
import { useRoute, useRouter } from 'vue-router'
import { videosSetsService } from '@/services/VideosSets.service'
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import CircleButton from '@/components/atoms/CircleButton.vue'
import { useStore } from 'vuex'
import Actors from '@/types/Actors'
import {
  setActorsDetailModalActive,
  setIsVideoPlayer,
  setActorData,
} from '@/store/ActorModal'
import IconThumbLike from '@/components/atoms/IconThumbLike.vue'
import IconThumbDislike from '@/components/atoms/IconThumbDislike.vue'

export default defineComponent({
  name: 'VideoPlayer',
  components: { IconThumbDislike, IconThumbLike, LoadingSpinner, CircleButton },
  setup() {
    const loadingSpinner = ref(null)
    const player = ref(null)
    const playerStyleSettings = ref(null)
    const poster = ref<string>(null)
    const title = ref<string>(null)
    const videoPlaySite = ref()
    const actors = ref<Actors[]>()
    const quality = ref<VideoQuality[]>([
      {
        default: false,
        html: '',
        url: '',
      },
    ])
    const router = useRouter()
    const route = useRoute()
    const videoId = ref<string | string[]>()
    const mainVideoUrl = ref<string>(null)
    const duration = ref<string>('')
    const likes = ref<number>(0)
    const store = useStore()
    const userData = computed(() => store.state.auth.user)
    let userLanguage: string
    if (!userData.value.language.includes('-')) {
      userLanguage = userData.value.language
    } else {
      const languageSplitString = userData.value.language.split('-')
      userLanguage = languageSplitString[0]
    }

    const getPlayerSettings = () => {
      const playerContainerWidth = videoPlaySite.value.clientWidth
      const playerHeight = Math.floor((playerContainerWidth / 16) * 9)
      playerStyleSettings.value = {
        width: videoPlaySite.value.clientWidth + 'px',
        height: playerHeight + 'px',
      }
    }
    watch(
      () => route.params.video_id,
      () => {
        if (player.value) {
          player.value.destroy()
        }
        getVideoData()
      },
    )
    onMounted(async () => {
      await getVideoData()
    })

    const getVideoData = async () => {
      quality.value = []
      videoId.value = route.params.video_id
      loadingSpinner.value.open()
      getPlayerSettings()
      await videosSetsService
        .getVideoUrl(videoId.value)
        .then((response) => {
          const data = response.data.data

          const h = Math.floor(data.duration / 3600)
          const m = Math.floor((data.duration % 3600) / 60)
          const s = Math.floor((data.duration % 3600) % 60)
          const hDisplay = h > 0 ? (h < 10 ? '0' : '') + h + ':' : ''
          const mDisplay = m > 0 ? (m < 10 ? '0' : '') + m : '00'
          const sDisplay = s > 0 ? (s < 10 ? '0' : '') + s : '00'
          duration.value = hDisplay + mDisplay + ':' + sDisplay

          poster.value =
            data.poster_image_data.video_set_preview_image +
            '/' +
            data.slug +
            '/1920x1080/' +
            data.poster_image_data.image_name

          for (let key in data.titles) {
            if (data.titles[key].language === userLanguage) {
              title.value = data.titles[key].title
            }
          }
          actors.value = data.actors

          likes.value = data.likes

          if (data.urls.sd) {
            mainVideoUrl.value = data.urls.hd
            quality.value.push({
              default: false,
              html: 'SD 480p',
              url: data.urls.sd,
            })
          }
          if (data.urls.hd) {
            quality.value.push({
              default: true,
              html: 'HD 720p',
              url: data.urls.hd,
            })
          }
          if (data.urls.full_hd) {
            quality.value.push({
              default: false,
              html: 'Full HD 1080p',
              url: data.urls.full_hd,
            })
          }
          loadingSpinner.value.close()
        })
        .catch((error) => {
          loadingSpinner.value.close()
          if (
            error.response.status === 403 &&
            error.response.data.data.message === 'no_pay_user'
          ) {
            router.push({ name: 'pay_packages' })
          }
        })

      const options = reactive({
        id: '1',
        container: '.player-container',
        url: mainVideoUrl.value,
        autoOrientation: true,
        whitelist: ['*'],
        title: title.value,
        poster: poster.value,
        volume: 0.5,
        isLive: false,
        muted: false,
        autoplay: false,
        autoPlayback: true,
        hotkey: true,
        pip: true,
        autoSize: true,
        autoMini: true,
        loop: false,
        flip: true,
        playbackRate: true,
        aspectRatio: true,
        fullscreen: true,
        fullscreenWeb: true,
        subtitleOffset: true,
        miniProgressBar: true,
        mutex: true,
        backdrop: true,
        theme: '#EC4899',
        quality: quality.value,
      })
      player.value = new Artplayer(options)
      player.value.on('ready', () => {
        player.value.url = mainVideoUrl.value
        player.value.poster = poster.value
      })
      await handleVideoView()
    }

    const handleVideoView = async () => {
      await videosSetsService
        .updateVideoViews(videoId.value)
        .then()
        .catch((error) => {
          console.error(error.message)
        })
    }

    const goBack = () => {
      router.back()
    }
    const setLike = async () => {
      await videosSetsService
        .sendLike(userData.value.id, videoId.value)
        .then((response) => {
          if (response.status === 201) {
            likes.value++
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
    const setDislike = async () => {
      await videosSetsService
        .sendDisLike(userData.value.id, videoId.value)
        .then((response) => {
          if (response.status === 204) {
            likes.value--
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }

    const handleActorClick = (actorId: number) => {
      setActorData(actorId)
      setActorsDetailModalActive(true)
      setIsVideoPlayer(true)
    }

    return {
      loadingSpinner,
      likes,
      setLike,
      setDislike,
      goBack,
      playerStyleSettings,
      title,
      videoPlaySite,
      duration,
      actors,
      handleActorClick,
    }
  },
})
</script>

<style scoped></style>
