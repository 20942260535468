<template>
  <div class="w-full h-full banner">
    <banner-skeleton v-if="isLoading" />
    <div v-if="!isLoading" class="banner-container relative w-full h-full">
      <div class="min-w-full min-h-full">
        <Image
          :src="bannerImageSrc"
          alt="banner"
          class="object-cover w-full h-full"
        />
      </div>

      <div class="banner__overlay absolute inset-0 flex items-center px-12">
        <transition
          appear
          enter-active-class="animate__animated animate__slideInUp"
          leave-active-class="animate__animated animate__slideInDown"
          mode="out-in"
        >
          <div class="w-1/3 space-y-6">
            <h1 class="text-3xl font-bold line-clamp-2">{{ bannerTitle }}</h1>

            <p class="text-lg line-clamp-4 font-medium">
              {{ bannerText }}
            </p>
            <div class="flex items-center space-x-2">
              <Button class="text-black bg-white" @click="handlePlayClick">
                <IconPlaySolid />
                <p class="text-bold">Play</p>
              </Button>
              <Button
                class="text-white bg-gray-600 hover:bg-opacity-40 shadow-lg"
                @click="handleInfoClick"
              >
                <IconInfoCircleOutlined />
                <p class="text-bold">More info</p>
              </Button>
            </div>
          </div>
        </transition>
      </div>

      <div class="banner__overlay--down absolute bottom-0 h-32 w-full"></div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue'
import Image from './atoms/Image.vue'
import Button from './atoms/Button.vue'
import BannerSkeleton from './skeleton/BannerSkeleton.vue'
import { videosSetsService } from '@/services/VideosSets.service'
import { useStore } from 'vuex'
import { setModalActive, setModalData } from '@/store/Modal'
import { useRouter } from 'vue-router'
import IconPlaySolid from '@/components/atoms/IconPlaySolid.vue'
import IconInfoCircleOutlined from '@/components/atoms/IconInfoCircleOutlined.vue'

export default defineComponent({
  components: {
    IconPlaySolid,
    IconInfoCircleOutlined,
    BannerSkeleton,
    Button,
    Image,
  },
  props: ['type'],

  setup() {
    let isLoading = ref<boolean>(false)
    const store = useStore()
    let bannerTitle = ref<string>(null)
    let bannerText = ref<string>(null)
    let bannerImageSrc = ref<string>(null)
    const userData = computed(() => store.state.auth.user)
    const router = useRouter()
    let videoId: number
    onMounted(async () => {
      isLoading.value = true
      await videosSetsService.getBannerVideo().then((response) => {
        let userLanguage: string
        if (!userData.value.language.includes('-')) {
          userLanguage = userData.value.language
        } else {
          const languageSplitString = userData.value.language.split('-')
          userLanguage = languageSplitString[0]
        }
        const videoData = response.data.data[0]
        bannerImageSrc.value =
          videoData.videos_sets_preview[0].video_set_preview_image +
          '/' +
          videoData.slug +
          '/1920x0/' +
          videoData.videos_sets_preview[0].image_name
        for (let key in videoData.videos_sets_descriptions) {
          if (
            videoData.videos_sets_descriptions[key].language === userLanguage
          ) {
            bannerTitle.value = videoData.videos_sets_descriptions[key].title
            bannerText.value =
              videoData.videos_sets_descriptions[key].description
          }
        }
        videoId = videoData.id
        isLoading.value = false
      })
    })

    const handleInfoClick = () => {
      setModalActive(true)
      setModalData(videoId)
    }

    const handlePlayClick = () => {
      if (userData.value.role === 'user') {
        console.warn('Kein Pay User')
      }
      router.push({ name: 'video', params: { video_id: videoId } })
    }

    return {
      BannerSkeleton,
      isLoading,
      bannerTitle,
      bannerText,
      bannerImageSrc,
      handleInfoClick,
      handlePlayClick,
    }
  },
})
</script>

<style>
.banner__overlay--down {
  background-image: linear-gradient(
    to bottom,
    transparent 10%,
    rgba(var(--background-color-rgb), 0.8),
    rgba(var(--background-color-rgb), 1)
  );
}

.banner__overlay {
  background-image: linear-gradient(
    77deg,
    rgba(0, 0, 0, 0.6) 25%,
    transparent 85%
  );
}
</style>
