import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full md:pb-44" }
const _hoisted_2 = { class: "px-4 xl:px-12 relative z-10 space-y-12 mt-20" }
const _hoisted_3 = { class: "space-y-2" }
const _hoisted_4 = { class: "xl:px-10" }
const _hoisted_5 = { class: "text-xl font-medium font-netflix_medium" }
const _hoisted_6 = { class: "italic underline decoration-1" }
const _hoisted_7 = {
  class: "flex flex-wrap items-center justify-center",
  ref: "scrollComponent"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_SeriesImagesCard = _resolveComponent("SeriesImagesCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LoadingSpinner, {
      ref: "loadingSpinner",
      "animation-duration": 1000,
      color: "#ed64a6",
      size: 60
    }, null, 512),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h1", _hoisted_5, [
              _createTextVNode(_toDisplayString(_ctx.$t('member_area.series_si')) + " >> ", 1),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.seriesTitle), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('member_area.images')), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.seriesImages, (imageSet) => {
                return (_openBlock(), _createBlock(_component_SeriesImagesCard, {
                  imageSet: imageSet,
                  key: imageSet.id
                }, null, 8, ["imageSet"]))
              }), 128))
            ], 512)
          ])
        ])
      ])
    ])
  ], 64))
}