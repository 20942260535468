import axios from 'axios'

class ActorsService {
  async getActors(
    search: string | null,
    perPage: number = null,
    page: number | string | string[] = null,
  ) {
    return await axios.get(
      `api/actors?per_page=${perPage}&page=${page}&search=${search}`,
    )
  }

  async getActor(actorId: number) {
    return await axios.get(`api/actor/${actorId}`)
  }
}

export const actorsService = new ActorsService()
