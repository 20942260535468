<template>
  <div
    v-if="type === 'element'"
    :class="[
      !layout.isContainer && 'animate-pulse bg-white bg-opacity-20',
      layout.class,
    ]"
  >
    <div v-if="layout.isContainer">
      <SkeletonItem
        v-for="(childItem, index) in layout.children"
        :layout="childItem"
        :key="index"
      />
    </div>
  </div>

  <SkeletonItem
    v-else-if="type === 'fragment'"
    v-for="(childItem, index) in layout.children"
    :layout="childItem"
    :key="index"
  />

  <component v-else :is="layout.type" v-bind="layout.props"></component>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'Skeletonitem',
  props: ['layout'],
  setup(props) {
    const type = computed(() => {
      if (props.layout.type === 'div') {
        return 'element'
      }
      if (typeof props.layout.type === 'symbol') {
        return 'fragment'
      }
      return 'component'
    })
    return {
      type,
    }
  },
})
</script>
