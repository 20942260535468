import './assets/styles/app.css'

import VueLazyload from '@jambonn/vue-lazyload'
import axios from 'axios'
import { createApp } from 'vue'
import Vue3TouchEvents from 'vue3-touch-events'
import App from './App.vue'
import Dashboard from './components/layout/Dashboard.vue'
import Empty from './components/layout/Empty.vue'

import router from './router'
import store from './store'

import de from '@/locales/de.json'
import en from '@/locales/en.json'
import es from '@/locales/es.json'
import fr from '@/locales/fr.json'
import it from '@/locales/it.json'
import { createI18n } from 'vue-i18n'

import VueGtag from 'vue-gtag'

type MessageSchema = typeof de

export const userLanguage = navigator.language ?? 'de'
export const i18n = createI18n<
  MessageSchema,
  'en' | 'en-US' | 'de' | 'es' | 'fr' | 'it'
>({
  locale: userLanguage,
  silentTranslationWarn: true,
  fallbackLocale: 'de',
  messages: {
    en: en,
    'en-US': en,
    de: de,
    es: es,
    fr: fr,
    it: it,
  },
})

axios.defaults.baseURL = process.env.VUE_APP_MEMBER_API_URL;

axios.defaults.withCredentials = true
// eslint-disable-next-line @typescript-eslint/no-var-requires
const loadImage = require('./assets/loading.svg')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const errorImage = require('./assets/error.png')

store.dispatch('auth/getAuthentication').then(() => {
  const app = createApp(App)

  app.component('default-layout', Dashboard)
  app.component('empty-layout', Empty)
  app
    .use(store)
    .use(i18n)
    .use(router)
    .use(Vue3TouchEvents, {
      touchHoldTolerance: 100,
    })
    .use(VueLazyload, {
      observer: true,
      preLoad: 1.3,
      attempt: 1,
      loading: loadImage,
      error: errorImage,
    })
    .use(
      VueGtag,
      {
        config: { id: 'G-FQQ95E66G7' },
      },
      router,
    )
    .mount('#app')
})
