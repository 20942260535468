<template>
  <div
    ref="container"
    class="cursor-pointer video-card relative transition-all duration-300 video-card-pt"
    :class="{
      'animate-card-hover': isScaled,
      'animate-card-unhover': !isScaled,
    }"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <Image
      :src="
        videoData.videos_sets_preview[0].video_set_preview_image +
        '/' +
        videoData.slug +
        '/340x0/' +
        videoData.videos_sets_preview[0].image_name
      "
      :class="{ 'rounded-b-none shadow': isScaled }"
      @click="handleClick"
    />
    <div
      v-if="isMouseEnter && isDesktop"
      class="absolute top-full w-full h-26 bg-background rounded-b-md transition-all duration-300 shadow p-3 space-y-2"
      :class="[!isScaled ? 'invisible opacity-0' : 'visible opacity-100']"
    >
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-2">
          <circle-button
            class="p-1 bg-white text-black border-white hover:bg-gray-300"
            @click="handlePlayClick"
          >
            <IconPlaySolid />
          </circle-button>
          <circle-button class="p-1" @click.prevent="setLike">
            <IconThumbLike />
          </circle-button>
          <circle-button class="p-1" @click.prevent="setDislike">
            <IconThumbDislike />
          </circle-button>
        </div>
      </div>
      <p class="line-clamp-1">{{ videoTitle }}</p>
      <div class="flex justify-between items-center space-x-2 text-xs">
        <div class="text-yellow-500">Likes: {{ likes }}</div>
        <div class="flex"><IconBxCalendar />{{ publishOn }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import VideoSet from '@/types/VideoSet'
import { computed, defineComponent, PropType, ref, toRefs } from 'vue'
import Image from './atoms/Image.vue'
import useDevice from '@/hooks/useDevice'
import CircleButton from './atoms/CircleButton.vue'
import { useStore } from 'vuex'
import moment from 'moment'
import { videosSetsService } from '@/services/VideosSets.service'
import { setModalActive, setModalData } from '@/store/Modal'
import { useRouter } from 'vue-router'
import IconPlaySolid from '@/components/atoms/IconPlaySolid.vue'
import IconThumbLike from '@/components/atoms/IconThumbLike.vue'
import IconThumbDislike from '@/components/atoms/IconThumbDislike.vue'
import IconBxCalendar from '@/components/atoms/IconBxCalendar.vue'

export default defineComponent({
  name: 'VideoCard',
  components: {
    IconBxCalendar,
    IconThumbDislike,
    IconThumbLike,
    IconPlaySolid,
    Image,
    CircleButton,
  },
  props: {
    videoData: {
      type: Object as PropType<VideoSet>,
    },
  },
  setup(props) {
    let { videoData } = toRefs(props)
    const store = useStore()
    const isScaled = ref(false)
    const timeout = ref(null)
    const container = ref(null)
    const isMouseEnter = ref(false)
    const mouseLeaveTimeout = ref(null)
    const router = useRouter()
    let videoTitle = ''
    let publishOn = ''
    const likes = ref(0)
    const { isDesktop } = useDevice()
    const userData = computed(() => store.state.auth.user)
    let userLanguage: string
    if (!userData.value.language.includes('-')) {
      userLanguage = userData.value.language
    } else {
      const languageSplitString = userData.value.language.split('-')
      userLanguage = languageSplitString[0]
    }
    const handleMouseEnter = () => {
      if (timeout.value) {
        clearTimeout(timeout.value)
      }
      if (mouseLeaveTimeout.value) {
        clearTimeout(mouseLeaveTimeout.value)
      }
      isMouseEnter.value = true
      timeout.value = setTimeout(() => {
        isScaled.value = true
      }, 500)
    }
    const handleMouseLeave = () => {
      if (timeout.value) {
        clearTimeout(timeout.value)
      }
      isScaled.value = false
      mouseLeaveTimeout.value = setTimeout(() => {
        isMouseEnter.value = false
      }, 500)
    }
    const videoId = videoData.value['id']
    likes.value = videoData.value['likes']
    publishOn = moment(videoData.value['publish_on']).format('DD.MM.YYYY')

    for (let key in videoData.value['videos_sets_descriptions']) {
      if (
        videoData.value['videos_sets_descriptions'][key].language ===
        userLanguage
      ) {
        videoTitle = videoData.value['videos_sets_descriptions'][key].title
      }
    }
    const setLike = async () => {
      await videosSetsService
        .sendLike(userData.value.id, videoId)
        .then((response) => {
          if (response.status === 201) {
            likes.value++
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
    const setDislike = async () => {
      await videosSetsService
        .sendDisLike(userData.value.id, videoId)
        .then((response) => {
          if (response.status === 204) {
            likes.value--
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }

    const handleClick = () => {
      setModalActive(true)
      setModalData(videoId)
    }

    const handlePlayClick = () => {
      if (userData.value.role === 'user') {
        console.warn('Kein Pay User')
      }
      router.push({ name: 'video', params: { video_id: videoId } })
    }

    return {
      isDesktop,
      isScaled,
      isMouseEnter,
      container,
      handleMouseEnter,
      handleMouseLeave,
      videoId,
      videoTitle,
      publishOn,
      likes,
      setLike,
      setDislike,
      handleClick,
      handlePlayClick,
    }
  },
})
</script>
<style scoped>
.video-card-pt {
  padding-top: 56.25%;
}
.shadow {
  -webkit-box-shadow: 0px 0px 12px 0px #000000;
  box-shadow: 0px 0px 12px 0px #000000;
}
.video-card img {
  @apply object-cover rounded-md absolute top-0 left-0 w-full h-full;
}
</style>
