<template>
  <LoadingSpinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#ed64a6"
    :size="60"
  ></LoadingSpinner>
  <div class="w-full h-full md:pb-44">
    <div class="px-4 xl:px-12 relative z-10 space-y-12 mt-20">
      <div class="space-y-2">
        <div class="xl:px-10">
          <h1 class="text-xl font-medium font-netflix_medium">
            {{ $t('member_area.actors') }}
          </h1>
          <div class="flex flex-wrap justify-center lg:justify-between">
            <div
              class="w-full h-10 pt-4 text-center lg:text-left lg:pl-10 lg:w-64 xl:mx-3 xl:pl-5"
            >
              <span>{{ results }} {{ $t('member_area.actors_found') }}</span>
            </div>
            <div class="w-64 pt-2 relative text-gray-200 mx-auto lg:mx-10">
              <input
                class="border-2 border-gray-200 bg-black h-10 px-5 pr-20 rounded-lg text-sm focus:outline-none"
                name="search"
                v-model="search"
                @change="searchActors"
                :placeholder="$t('member_area.search')"
              />
              <button type="submit" class="absolute right-0 top-0 mt-5 mr-4">
                <svg
                  class="text-gray-200 h-4 w-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 56.966 56.966"
                  xml:space="preserve"
                  width="512px"
                  height="512px"
                >
                  <path
                    d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23
                s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92
                c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z
                M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div
            class="flex flex-wrap justify-center w-full items-center scrolling-component"
            ref="scrollComponent"
          >
            <ActorCard
              v-for="actor in actors"
              :key="actor.id"
              :actor-data="actor"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <transition
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
  >
    <ActorDetailsModal v-if="isActorsDetailModalActive" />
  </transition>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
  toRefs,
  watch,
} from 'vue'
import useDevice from '@/hooks/useDevice'
import { useRouter } from 'vue-router'
import Actors from '@/types/Actors'
import { actorsService } from '@/services/Actors.Service'
import ActorCard from '@/components/atoms/ActorCard.vue'
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import { state } from '@/store/ActorModal'
import ActorDetailsModal from '@/components/ActorDetailsModal.vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Actors',
  components: { ActorCard, LoadingSpinner, ActorDetailsModal },
  setup() {
    const loadingSpinner = ref(null)
    const scrollComponent = ref(null)
    const isDesktop = useDevice().isDesktop
    const router = useRouter()
    const store = useStore()
    const userData = computed(() => store.state.auth.user)

    const search = ref('')
    let page = 1
    let lastPage: number
    let perPage = 10
    const actors = ref<Actors[]>([])
    const results = ref<number>(0)

    onBeforeMount(async () => {
      await getActorsData('', perPage, page)
    })
    onMounted(() => {
      window.addEventListener('scroll', handleActorsScrolling, true)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleActorsScrolling, true)
    })

    const searchActors = async (event) => {
      search.value = event.target.value
      page = 1
      await getActorsData(search.value, perPage, page)
    }

    const getActorsData = async (
      search: string,
      perPage: number,
      page: number,
    ) => {
      await actorsService
        .getActors(search, perPage, page)
        .then((response) => {
          const userRole = userData.value.role
          if (userRole === 'user') {
            router.push({ name: 'pay_packages' })
          }
          lastPage = response.data.meta.last_page
          results.value = response.data.meta.total
          actors.value = response.data.data
        })
        .catch((error) => {
          if (
            error.response.status === 403 &&
            error.response.statusText === 'Forbidden'
          ) {
            router.push({ name: 'forbidden' })
          }
        })
    }

    const loadMoreActors = async () => {
      page++
      if (!(page > lastPage)) {
        await actorsService
          .getActors(search.value, perPage, page)
          .then((response) => {
            lastPage = response.data.meta.last_page
            results.value = response.data.meta.total
            actors.value.push(...response.data.data)
            loadingSpinner.value.close()
          })
          .catch((error) => {
            console.log(error)
            if (
              error.response.status === 403 &&
              error.response.statusText === 'Forbidden'
            ) {
              router.push({ name: 'forbidden' })
            }
          })
      }
    }
    const handleActorsScrolling = () => {
      let element = scrollComponent.value
      if (element.getBoundingClientRect().bottom < window.innerHeight) {
        loadMoreActors()
      }
    }
    watch(
      () => state.isActorsDetailModalActive,
      () => {
        setTimeout(function () {
          window.scrollTo({ left: 0, top: state.scrollTop })
        }, 0)
      },
    )

    return {
      getActorsData,
      searchActors,
      results,
      search,
      loadingSpinner,
      scrollComponent,
      isDesktop,
      actors,
      ...toRefs(state),
    }
  },
})
</script>

<style scoped></style>
