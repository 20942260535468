<template>
  <div
    class="z-60 bg-black bg-opacity-60 top-0 fixed h-screen w-full overflow-y-scroll"
    @click.self="handleCloseClick"
  >
    <div class="info-container">
      <details-modal-skeleton v-if="isLoading" />
      <div v-else>
        <div class="relative w-full h-[30rem]">
          <Image :src="imageLink" class="w-full h-full object-cover" />

          <circle-button
            class="z-50 absolute top-5 right-5 bg-background text-white"
            @click="handleCloseClick"
          >
            <IconWindowCloseLine />
          </circle-button>

          <div class="info__overlay">
            <h1 class="text-2xl font-netflix_medium mb-6">{{ videoTitle }}</h1>

            <div class="flex items-center space-x-2">
              <Button class="text-black bg-white" @click="handlePlayClick">
                <IconPlaySolid />
                <p class="text-bold">Play</p>
              </Button>
              <circle-button class="p-1" @click="setLike">
                <IconThumbLike />
              </circle-button>
              <circle-button class="p-1" @click="setDislike">
                <IconThumbDislike />
              </circle-button>
            </div>
          </div>
        </div>

        <div class="space-y-8 px-4 md:px-12 my-3">
          <div class="md:space-x-8 flex flex-col md:flex-row space-y-4">
            <div class="flex-1 flex-grow-[2] space-y-4">
              <p class="text-base text-gray-300">{{ seriesDescription }}</p>

              <div class="flex items-center space-x-2">
                <div class="flex items-center text-yellow-500">
                  <p>Likes: {{ likes }}</p>
                </div>

                <p>{{ publishOn }}</p>

                <p class="border border-gray-500 px-2">18+</p>
              </div>
            </div>

            <div class="mt-4 md:mt-0 flex-1 space-y-2 text-sm">
              <div class="space-x-2">
                <span class="text-gray-400">Series:</span>
                <span class="text-white"> {{ seriesTitle }} </span>
              </div>

              <div class="space-x-2">
                <span class="text-gray-400">Sprache:</span>
                <span class="text-white"> {{ videoLanguage }}</span>
              </div>
            </div>
          </div>
          <div>
            <p class="text-base hidden md:block">{{ videoDescription }}</p>
          </div>

          <h2 class="text-base font-netflix_medium">Preview Image</h2>
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div
              v-for="(item, key) in previewImages"
              :key="key"
              class="relative"
            >
              <div class="relative">
                <Image :src="item" class="w-full h-full object-cover" />
              </div>
              <div
                class="opacity-0 transition-all duration-300 hover:opacity-100 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full p-4 bg-black bg-opacity-80 border border-gray-300 cursor-pointer"
                @click="handlePlayClick"
              >
                <IconPlaySolid />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { state, setModalActive } from '@/store/Modal'
import CircleButton from '@/components/atoms/CircleButton.vue'
import Button from '@/components/atoms/Button.vue'
import Image from '@/components/atoms/Image.vue'
import { videosSetsService } from '@/services/VideosSets.service'
import { useStore } from 'vuex'
import moment from 'moment'
import useDevice from '@/hooks/useDevice'
import { useRouter } from 'vue-router'
import IconWindowCloseLine from '@/components/atoms/IconWindowCloseLine.vue'
import IconThumbLike from '@/components/atoms/IconThumbLike.vue'
import IconPlaySolid from '@/components/atoms/IconPlaySolid.vue'
import IconThumbDislike from '@/components/atoms/IconThumbDislike.vue'

export default defineComponent({
  name: 'DetailsModal',
  components: {
    IconThumbDislike,
    CircleButton,
    Button,
    Image,
    IconWindowCloseLine,
    IconThumbLike,
    IconPlaySolid,
  },
  props: {
    video_id: {
      type: Number,
    },
  },
  setup() {
    const handleCloseClick = () => {
      setModalActive(false)
    }
    const store = useStore()
    const userData = computed(() => store.state.auth.user)
    let userLanguage: string
    if (!userData.value.language.includes('-')) {
      userLanguage = userData.value.language
    } else {
      const languageSplitString = userData.value.language.split('-')
      userLanguage = languageSplitString[0]
    }
    const imageLink = ref<string>(null)
    const videoTitle = ref<string>(null)
    const videoDescription = ref<string>(null)
    const videoLanguage = ref<string>(null)
    const seriesTitle = ref<string>(null)
    const seriesDescription = ref<string>(null)
    const likes = ref<number>(0)
    const publishOn = ref<string>(null)
    const previewImages = ref<Array<string>>([])
    const videoId = state.modalData
    const { isDesktop } = useDevice()

    const router = useRouter()

    onBeforeMount(async () => {
      await videosSetsService
        .getVideoData(videoId)
        .then((response) => {
          const data = response.data.data
          imageLink.value =
            data.videos_sets_preview[0].video_set_preview_image +
            '/' +
            data.slug +
            '/680x454/' +
            data.videos_sets_preview[0].image_name

          previewImages.value = data.videos_sets_preview.map((item) => {
            return (
              item.video_set_preview_image +
              '/' +
              data.slug +
              '/340x227/' +
              item.image_name
            )
          })

          videoLanguage.value = data.series.content_origin
          seriesTitle.value = data.series.title
          seriesDescription.value = data.series.description
          likes.value = data.likes
          publishOn.value = moment(data.publish_on).format('DD.MM.YYYY')
          for (let key in data.videos_sets_descriptions) {
            if (data.videos_sets_descriptions[key].language === userLanguage) {
              videoTitle.value = data.videos_sets_descriptions[key].title
              videoDescription.value =
                data.videos_sets_descriptions[key].description
            }
          }
        })
        .catch((error) => {
          console.error(error)
        })
    })

    const setLike = async () => {
      await videosSetsService
        .sendLike(userData.value.id, videoId)
        .then((response) => {
          if (response.status === 201) {
            likes.value++
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
    const setDislike = async () => {
      await videosSetsService
        .sendDisLike(userData.value.id, videoId)
        .then((response) => {
          if (response.status === 204) {
            likes.value--
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }

    const handlePlayClick = () => {
      if (userData.value.role === 'user') {
        console.warn('Kein Pay User')
      }
      setModalActive(false)
      router.push({ name: 'video', params: { video_id: videoId } })
    }

    return {
      isDesktop,
      handleCloseClick,
      imageLink,
      videoTitle,
      videoDescription,
      videoLanguage,
      seriesTitle,
      seriesDescription,
      likes,
      publishOn,
      previewImages,
      setLike,
      setDislike,
      handlePlayClick,
    }
  },
})
</script>

<style scoped>
.info-container {
  @apply bg-background md:rounded-xl lg:w-2/3 md:w-4/5  w-full max-w-4xl min-h-screen md:mt-2 shadow overflow-hidden mx-auto;
  -webkit-box-shadow: 0px 0px 12px 0px #000000;
  box-shadow: 0px 0px 12px 0px #000000;
}

.info__overlay {
  @apply p-4 md:p-12 absolute top-0 inset-0 flex flex-col justify-end space-y-2;
  background: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(var(--background-color-rgb), 1)
  );
}
</style>
