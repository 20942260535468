import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "space-y-2" }
const _hoisted_2 = { class: "text-xl font-medium font-netflix_medium" }
const _hoisted_3 = { class: "swiper-button swiper-button-prev group cursor-pointer flex items-center justify-center" }
const _hoisted_4 = { class: "swiper-button group cursor-pointer swiper-button-next flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_video_card = _resolveComponent("video-card")!
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_IconArrowRightAlt2 = _resolveComponent("IconArrowRightAlt2")!
  const _component_IconArrowLeftAlt2 = _resolveComponent("IconArrowLeftAlt2")!
  const _component_Swiper = _resolveComponent("Swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.carouselName), 1),
    _createElementVNode("div", {
      ref: "container",
      class: _normalizeClass(["carousel-container relative", [_ctx.childHovering ? 'animate-z-hover' : 'animate-z-unhover']])
    }, [
      _createVNode(_component_Swiper, _mergeProps(_ctx.options, {
        onSwiper: _ctx.onReady,
        onSlideChange: _ctx.handleToggleButton
      }), {
        "container-end": _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_IconArrowRightAlt2, { class: "text-3xl" })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_IconArrowLeftAlt2, { class: "text-3xl" })
          ])
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createBlock(_component_SwiperSlide, {
              key: item.id,
              class: "slides"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_video_card, { videoData: item }, null, 8, ["videoData"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 16, ["onSwiper", "onSlideChange"])
    ], 2)
  ]))
}