<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div
    class="bg-cover h-screen px-6"
    style="
      background-image: url('https://images.sextermedia.com/content/images/background/0x0/sextermedia_background.webp');
    "
  >
    <div class="flex justify-center items-center h-3/4">
      <div class="p-6 max-w-sm w-full bg-black shadow-md rounded-md">
        <div class="flex justify-center items-center">
          <span class="text-white font-semibold text-2xl">
            {{ $t('member_form.form.reset_password_title') }}</span
          >
        </div>
        <div class="justify-center items-center" v-if="status_message">
          <span class="text-green-300 font-medium mb-3"
            >{{ status_message }}
            <router-link :to="{ name: 'login' }"
              >{{ $t('member_form.form.login_link_text') }}
            </router-link></span
          >
        </div>
        <div class="flex justify-center items-center" v-if="error">
          <span class="text-red-500 font-medium">{{ error }}</span>
        </div>
        <form class="mt-4" @submit.prevent="resetPassword">
          <div class="mb-6">
            <label for="email" class="inline-block text-sm mb-2">
              {{ $t('member_form.form.label_email_text') }}:
            </label>
            <input
              type="email"
              id="email"
              class="w-full h-10 bg-gray-800 text-white px-3 rounded-sm"
              v-model="form.email"
            />
          </div>
          <div class="mb-6">
            <label for="password" class="inline-block text-sm mb-2">
              {{ $t('member_form.form.label_password_text') }}:
            </label>
            <input
              type="password"
              id="password"
              class="w-full h-10 bg-gray-800 text-white px-3 rounded-sm"
              v-model="form.password"
            />
            <span v-if="v$.form.password.$error" class="text-red-600">
              <p v-for="(error, key) in v$.form.password.$errors" :key="key">
                {{ error.$message }}
              </p>
            </span>
          </div>
          <div class="mb-6">
            <label for="password" class="inline-block text-sm mb-2">
              {{ $t('member_form.form.label_password_confirmation_text') }}:
            </label>
            <input
              type="password"
              id="password_confirmation"
              class="w-full h-10 bg-gray-800 text-white px-3 rounded-sm"
              v-model="form.password_confirmation"
            />
            <span
              v-if="v$.form.password_confirmation.$error"
              class="text-red-600"
            >
              <p
                v-for="(error, key) in v$.form.password_confirmation.$errors"
                :key="key"
              >
                {{ error.$message }}
              </p>
            </span>
          </div>
          <div class="mt-6">
            <button
              type="submit"
              class="py-2 px-4 text-center bg-pink-700 rounded-md w-full text-white text-sm hover:bg-pink-800"
              :disabled="v$.$invalid"
            >
              {{ $t('member_form.form.reset_password_button_text') }}
            </button>
          </div>
          <div class="mt-5"></div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import { newPasswordService } from '@/services/NewPassword.Service'
import ResetPasswordData from '@/types/ResetPasswordData'
import useVuelidate from '@vuelidate/core'
import {
    alphaNum,
    email,
    minLength,
    required,
    sameAs,
} from '@vuelidate/validators'
import { computed, defineComponent, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'ResetPassword',
  components: {
    LoadingSpinner,
  },
  setup() {
    const status_message = ref<string>()
    const route = useRoute()
    const token = ref<string | string[]>('')
    const form = ref<ResetPasswordData>({
      token: '',
      email: '',
      password: '',
      password_confirmation: '',
    })

    let error = ref<string[]>(null)
    const state = reactive({
      form,
    })

    const rules = computed(() => {
      return {
        form: {
          email: { required, email, $autoDirty: true },
          password: {
            required,
            minLengthValue: minLength(10),
            alphaNum,
            $autoDirty: true,
          },
          password_confirmation: {
            required,
            sameAs: sameAs(form.value.password),
            $autoDirty: true,
          },
        },
      }
    })
    const v$ = useVuelidate(rules, state)

    const resetPassword = async () => {
      error.value = null
      token.value = route.params['token']
      if (token.value) {
        form.value.token = token.value
      }
      console.log(token.value)
      await newPasswordService
        .resetPasswordData(form.value)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data['status'])
            status_message.value = response.data['status']
          }
        })
        .catch((error_response) => {
          const errorsObject = error_response.response.data
          error.value = Object.keys(errorsObject).map((errorIndex) => {
            return errorsObject[errorIndex]
          })
        })
    }

    return {
      form,
      v$,
      error,
      status_message,
      resetPassword,
    }
  },
})
</script>

<style scoped></style>
