<template>
  <div
    class="transition-all duration-300 md:max-w-xs w-full my-3 xl:mx-3 mx-1 serie-card"
  >
    <div class="bg-color items-center w-full cursor-pointer relative">
      <Image
        :src="
          imageSet.preview_image.preview_image_url +
          '/340x227/' +
          imageSet.preview_image.image_name
        "
        @click="handleClickOnImage"
      />
      <div
        class="absolute transition-all left-32 top-1/3 rounded-full p-4 bg-black bg-opacity-80 border border-gray-300 cursor-pointer"
        @click="handleClickOnImage"
      >
        <IconImagesAlt class="text-3xl" />
      </div>
    </div>

    <div class="p-4 w-full bg-color space-y-2 rounded-b-md">
      <div class="">
        <p class="line-clamp-1">{{ title }}</p>
        <p class="text-sm text-gray-400 line-clamp-6">
          {{ description }}
        </p>
      </div>
      <div class="flex justify-between space-x-2 text-xs content-center">
        <div class="text-bold">
          <div class="flex">
            <circle-button class="p-1" @click="setLike">
              <IconThumbLike class="text-3xl" />
            </circle-button>
            <circle-button class="p-1 ml-3" @click="setDislike">
              <IconThumbDislike class="text-3xl" />
            </circle-button>
            <circle-button class="p-1 ml-3" @click="setFavorites">
              <IconNoFavorite class="text-3xl" v-if="!isFavorite" />
              <IconIsFavorite class="text-3xl" v-if="isFavorite" />
            </circle-button>
          </div>
          <p class="ml-4 pt-1">{{ likes }}</p>
        </div>

        <div class="text-bold">
          <circle-button class="p-1" @click="handleClickOnImage">
            <IconImagesAlt class="text-3xl" />
          </circle-button>
          <p class="pl-3.5 pt-1">{{ image_count }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, toRefs } from 'vue'
import Image from '@/components/atoms/Image.vue'
import CircleButton from './atoms/CircleButton.vue'
import SeriesImages from '@/types/SeriesImages'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { galleryService } from '@/services/Gallery.Service'
import IconImagesAlt from '@/components/atoms/IconImagesAlt.vue'
import IconThumbLike from '@/components/atoms/IconThumbLike.vue'
import IconThumbDislike from '@/components/atoms/IconThumbDislike.vue'
import IconNoFavorite from '@/components/atoms/IconNoFavorite.vue'
import IconIsFavorite from '@/components/atoms/IconIsFavorite.vue'

export default defineComponent({
  name: 'SeriesImagesCard',
  props: {
    imageSet: {
      type: Object as PropType<SeriesImages>,
    },
  },
  components: {
    IconIsFavorite,
    IconNoFavorite,
    IconImagesAlt,
    IconThumbLike,
    IconThumbDislike,
    Image,
    CircleButton,
  },
  setup(props) {
    let { imageSet } = toRefs(props)
    const store = useStore()
    const userData = computed(() => store.state.auth.user)
    let userLanguage: string
    const title = ref<string>('')
    const description = ref<string>('')
    const content_id = ref<number>()
    const image_count = ref<number>()
    const likes = ref<number>(0)
    const isFavorite = ref<boolean>(false)
    const router = useRouter()
    if (!userData.value.language.includes('-')) {
      userLanguage = userData.value.language
    } else {
      const languageSplitString = userData.value.language.split('-')
      userLanguage = languageSplitString[0]
    }
    content_id.value = imageSet.value['id']
    image_count.value = imageSet.value['image_count']
    likes.value = imageSet.value['likes']
    for (let key in imageSet.value['images_set_description']) {
      if (
        imageSet.value['images_set_description'][key].language === userLanguage
      ) {
        title.value = imageSet.value['images_set_description'][key].title
        description.value =
          imageSet.value['images_set_description'][key].description
      }
    }
    if (imageSet.value['isFavorite'].length > 0) {
      isFavorite.value = imageSet.value['isFavorite'][0].isFavorite
    }

    const setLike = async () => {
      await galleryService
        .sendLike(userData.value.id, content_id.value)
        .then((response) => {
          if (response.status === 201) {
            likes.value = +1
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
    const setDislike = async () => {
      await galleryService
        .sendDisLike(userData.value.id, content_id.value)
        .then((response) => {
          if (response.status === 204) {
            likes.value = -1
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }

    const setFavorites = async () => {
      if (!isFavorite.value) {
        await galleryService
          .addToFavorites(userData.value.id, content_id.value)
          .then((response) => {
            if (response.status === 201) {
              return response.data
            }
          })
          .catch((error) => {
            console.error(error)
          })
      }
      if (isFavorite.value) {
        await galleryService
          .removeToFavorites(userData.value.id, content_id.value)
          .then((response) => {
            if (response.status === 204) {
              return response.data
            }
          })
          .catch((error) => {
            console.error(error)
          })
      }

      isFavorite.value = !isFavorite.value
    }

    const handleClickOnImage = () => {
      router.push({
        name: 'gallery',
        params: { image_set_id: content_id.value },
      })
    }

    return {
      title,
      description,
      content_id,
      image_count,
      likes,
      isFavorite,
      handleClickOnImage,
      setLike,
      setDislike,
      setFavorites,
    }
  },
})
</script>

<style scoped>
.bg-color {
  background: #1e1e1e;
}
.serie-card img {
  @apply object-cover rounded-md w-full h-full;
}
</style>
