import axios from 'axios'

class GalleryService {
  async getImages(imagesSetId: string | string[]) {
    return await axios.get(`api/images_set/${imagesSetId}`)
  }

  async sendLike(userId: string, contentId: number) {
    return await axios.post(
      `api/content-like/${userId}?images_set=${contentId}`,
    )
  }
  async sendDisLike(userId: string, contentId: number) {
    return await axios.delete(
      `api/content-dislike/${userId}?images_set=${contentId}`,
    )
  }
  async addToFavorites(userId: string, videoId: number | string | string[]) {
    return await axios.post(
      `api/user-favorites/${userId}?images_set=${videoId}`,
    )
  }

  async removeToFavorites(userId: string, videoId: number | string | string[]) {
    return await axios.delete(
      `api/user-favorites/${userId}?images_set=${videoId}`,
    )
  }
}

export const galleryService = new GalleryService()
