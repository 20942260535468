import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full md:pb-44" }
const _hoisted_2 = { class: "px-4 xl:px-12 relative z-10 space-y-12 mt-20" }
const _hoisted_3 = { class: "space-y-2" }
const _hoisted_4 = {
  key: 0,
  class: "xl:px-10"
}
const _hoisted_5 = { class: "text-xl font-light font-netflix_medium" }
const _hoisted_6 = {
  key: 0,
  class: "text-xl font-light font-netflix_medium mt-5"
}
const _hoisted_7 = {
  key: 1,
  class: "flex flex-wrap items-center justify-center",
  ref: "scrollComponent"
}
const _hoisted_8 = {
  key: 2,
  class: "text-xl font-light font-netflix_medium mt-5"
}
const _hoisted_9 = {
  key: 3,
  class: "flex flex-wrap items-center justify-center"
}
const _hoisted_10 = {
  key: 1,
  class: "xl:px-10"
}
const _hoisted_11 = { class: "text-xl font-light font-netflix_medium" }
const _hoisted_12 = { class: "text-xl font-light font-netflix_medium mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_spinner = _resolveComponent("loading-spinner")!
  const _component_SeriesVideoCard = _resolveComponent("SeriesVideoCard")!
  const _component_SeriesImagesCard = _resolveComponent("SeriesImagesCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading_spinner, {
      ref: "loadingSpinner",
      "animation-duration": 1000,
      color: "#818CF8",
      size: 60
    }, null, 512),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.favorites)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('member_area.user_favorites')), 1),
                (_ctx.favorites.videos_sets)
                  ? (_openBlock(), _createElementBlock("h2", _hoisted_6, _toDisplayString(_ctx.$t('member_area.favoriten_area_videos')), 1))
                  : _createCommentVNode("", true),
                (_ctx.favorites.videos_sets)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.favorites.videos_sets, (videoSet) => {
                        return (_openBlock(), _createBlock(_component_SeriesVideoCard, {
                          "video-set": videoSet,
                          key: videoSet.id
                        }, null, 8, ["video-set"]))
                      }), 128))
                    ], 512))
                  : _createCommentVNode("", true),
                (_ctx.favorites.videos_sets)
                  ? (_openBlock(), _createElementBlock("h2", _hoisted_8, _toDisplayString(_ctx.$t('member_area.favoriten_area_images')), 1))
                  : _createCommentVNode("", true),
                (_ctx.favorites.image_sets)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.favorites.image_sets, (imageSet) => {
                        return (_openBlock(), _createBlock(_component_SeriesImagesCard, {
                          imageSet: imageSet,
                          key: imageSet.id
                        }, null, 8, ["imageSet"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (!_ctx.favorites)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("h1", _hoisted_11, _toDisplayString(_ctx.$t('member_area.user_favorites')), 1),
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('member_area.no_favorites')), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}