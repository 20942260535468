import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full md:pb-44" }
const _hoisted_2 = { class: "w-full h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Banner = _resolveComponent("Banner")!
  const _component_VideoCarousel = _resolveComponent("VideoCarousel")!
  const _component_DetailsModal = _resolveComponent("DetailsModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isDesktop)
          ? (_openBlock(), _createBlock(_component_Banner, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["px-4 md:px-12 relative z-10 space-y-12", [_ctx.isDesktop ? '-mt-28' : 'mt-20']])
      }, [
        _createVNode(_component_VideoCarousel, {
          items: _ctx.videosData,
          carouselName: _ctx.$t('member_area.dashboard.new_videos')
        }, null, 8, ["items", "carouselName"]),
        _createVNode(_component_VideoCarousel, {
          items: _ctx.topVideosData,
          carouselName: _ctx.$t('member_area.dashboard.top_videos')
        }, null, 8, ["items", "carouselName"]),
        _createVNode(_component_VideoCarousel, {
          items: _ctx.topLikedVideos,
          carouselName: _ctx.$t('member_area.dashboard.top_liked_videos')
        }, null, 8, ["items", "carouselName"]),
        _createVNode(_component_VideoCarousel, {
          items: _ctx.highlightsVideos,
          carouselName: _ctx.$t('member_area.dashboard.highlights_videos')
        }, null, 8, ["items", "carouselName"]),
        _createVNode(_component_VideoCarousel, {
          items: _ctx.specialVideos,
          carouselName: _ctx.$t('member_area.dashboard.special_videos')
        }, null, 8, ["items", "carouselName"])
      ], 2)
    ]),
    _createVNode(_Transition, {
      "enter-active-class": "animate__animated animate__fadeIn",
      "leave-active-class": "animate__animated animate__fadeOut"
    }, {
      default: _withCtx(() => [
        (_ctx.isModalActive)
          ? (_openBlock(), _createBlock(_component_DetailsModal, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}