<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div
    class="bg-cover h-screen px-6"
    style="
      background-image: url('https://images.sextermedia.com/content/images/background/0x0/sextermedia_background.webp');
    "
  >
    <div class="flex pt-4 pl-4">
      <img src="../assets/sextermedia-logo.png" />
    </div>
    <div class="flex justify-center items-center h-3/4">
      <div class="p-6 max-w-sm w-full bg-black shadow-md rounded-md">
        <div class="flex justify-center items-center">
          <span class="text-white font-semibold text-2xl">{{
            $t('thank_you_page.title')
          }}</span>
        </div>
        <div class="flex w-full mt-10 text-justify">
          <p>
            {{ $t('thank_you_page.body_text') }}
          </p>
        </div>
        <!--  <div class="w-full mt-10 text-center">
          {{ $t('thank_you_page.no_email_get_text') }}<br />
          <a class="cursor-pointer" @click.passive="resendEmail">
            {{ $t('thank_you_page.resend_email_link_text') }}
          </a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue';
import { registerService } from '@/services/Register.Service';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'ThankYouPage',
  components: {
    LoadingSpinner,
  },
  setup() {
    const loadingSpinner = ref(null)
    const status_message = ref<string>()
    const store = useStore()
    const userData = computed(() => store.state.auth.user)
    const resendEmail = async () => {
      loadingSpinner.value.open()

      await registerService
        .resendVerifyEmail(userData.value)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data['message'])
            status_message.value = response.data['message']
            loadingSpinner.value.close()
          }
        })
        .catch((error_response) => {
          console.log(error_response.response.data)
          loadingSpinner.value.close()
        })
    }
    return {
      loadingSpinner,
      resendEmail,
    }
  },
})
</script>

<style scoped></style>
