<template>
  <skeleton>
    <div class="h-12 w-28 rounded-md"></div>
  </skeleton>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import Skeleton from './index.vue'
export default defineComponent({
  name: 'ButtonSkeleton',
  components: { Skeleton },
})
</script>
