<template>
  <div
    class="z-60 bg-black bg-opacity-80 top-0 fixed h-screen w-full overflow-y-scroll flex"
    @click.self="handleCloseClick()"
  >
    <div class="image-container flex flex-row">
      <div class="nav-button" @click="handlePrevious" v-if="isDesktop">
        <IconArrowLeftAlt2 class="text-9xl text-white" />
      </div>
      <div
        class="image-wraper"
        :class="{
          'animate-card-hover': isScaled,
          'animate-card-unhover': !isScaled,
        }"
      >
        <div class="relative">
          <Image
            :src="image"
            class="w-full h-full md:rounded-xl"
            @touchstart="handleTouchstart"
            @touchmove="handleSwipeEvent"
            @touchend="handleTouchend"
            @contextmenu="handleRightClick"
          />
          <circle-button
            class="z-50 absolute top-5 right-5 bg-background text-white"
            v-if="isDesktop"
            @click="handleCloseClick"
          >
            <IconWindowCloseLine class="text-2xl" />
          </circle-button>
        </div>
      </div>
      <div class="nav-button" @click="handleNext" v-if="isDesktop">
        <IconArrowRightAlt2 class="text-9xl text-white" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { setLightBoxActive, setLightBoxData, state } from '@/store/LightBox'
import Image from '@/components/atoms/Image.vue'
import CircleButton from '@/components/atoms/CircleButton.vue'
import LightBoxData from '@/types/LightBoxData'
import useDevice from '@/hooks/useDevice'
import IconArrowLeftAlt2 from '@/components/atoms/IconArrowLeftAlt2.vue'
import IconArrowRightAlt2 from '@/components/atoms/IconArrowRightAlt2.vue'
import IconWindowCloseLine from '@/components/atoms/IconWindowCloseLine.vue'

export default defineComponent({
  name: 'Lightbox',
  components: {
    CircleButton,
    Image,
    IconArrowLeftAlt2,
    IconArrowRightAlt2,
    IconWindowCloseLine,
  },
  setup() {
    const isDesktop = useDevice().isDesktop
    const isScaled = ref(false)
    const image = ref<string>(null)
    const images = ref<string[]>()
    const lightBoxData: LightBoxData = { current: 0, images: [] }
    images.value = state.lightBoxData['images']
    image.value = images.value[state.lightBoxData['current']]
    let startX = 0
    let moveDistance = 0
    let leftMove = false
    let rightMove = false
    const handleCloseClick = () => {
      setLightBoxActive(false)
    }

    const handlePrevious = () => {
      if (state.lightBoxData['current'] !== 0) {
        lightBoxData.images = images.value
        lightBoxData.current = state.lightBoxData['current'] - 1
        setLightBoxData(lightBoxData)
        image.value = images.value[state.lightBoxData['current']]
      }
    }
    const handleNext = () => {
      const lastId = images.value.length - 1
      if (lastId !== state.lightBoxData['current']) {
        lightBoxData.images = images.value
        lightBoxData.current = state.lightBoxData['current'] + 1
        setLightBoxData(lightBoxData)
        image.value = images.value[state.lightBoxData['current']]
      }
    }

    const handleTouchstart = (event) => {
      startX = event.touches[0].pageX
    }

    const handleSwipeEvent = (event) => {
      //console.log(event)
      moveDistance = event.touches[0].pageX - startX

      if (startX > moveDistance) {
        leftMove = true
        rightMove = false
      }

      if (moveDistance > startX) {
        rightMove = true
        leftMove = false
      }
    }

    const handleTouchend = () => {
      changeImage()
      leftMove = false
      rightMove = false
    }

    const changeImage = () => {
      const lastId = images.value.length - 1

      if (leftMove && lastId !== state.lightBoxData['current']) {
        lightBoxData.images = images.value
        lightBoxData.current = state.lightBoxData['current'] + 1
        setLightBoxData(lightBoxData)
        image.value = images.value[state.lightBoxData['current']]
      }
      if (rightMove && state.lightBoxData['current'] !== 0) {
        lightBoxData.images = images.value
        lightBoxData.current = state.lightBoxData['current'] - 1
        setLightBoxData(lightBoxData)
        image.value = images.value[state.lightBoxData['current']]
      }
    }

    const handleRightClick = (event) => {
      event.preventDefault()
    }

    return {
      isScaled,
      handleCloseClick,
      image,
      handleNext,
      handlePrevious,
      handleTouchstart,
      handleTouchend,
      handleSwipeEvent,
      handleRightClick,
      isDesktop,
    }
  },
})
</script>

<style scoped>
.image-container {
  @apply w-full my-auto overflow-hidden mx-auto;
}
.image-wraper {
  @apply lg:w-10/12 md:w-10/12 w-full;
}
.nav-button {
  @apply my-auto cursor-pointer w-1/12;
}
</style>
