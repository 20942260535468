<template>
  <div
    class="w-full rounded-xl shadow hover:shadow-xl transition duration-100 ease-in-out p-6 md:mr-4 mb-10 md:mb-0"
    :class="[
      paymentPackage.is_highlight
        ? 'bg-gray-800 text-white'
        : 'bg-gray-800 text-white',
      count === 3 ? ' md:w-1/3' : ' md:w-1/2',
      count === 1 ? ' md:w-full' : '',
    ]"
  >
    <h2
      class="font-bold text-pink-500 text-4xl md:text-5xl mb-5 text-center"
      v-if="paymentPackage.price_before > 0"
    >
      {{ $tc('price_table.special_offer') }}
    </h2>
    <p v-if="paymentPackage.price_before > 0" class=" text-pink-500 text-2xl md:text-2xl mb-5 text-center">
      {{ $tc('price_table.only_for_you') }} {{ userName }}
    </p>
    <p class="text-gray-600 mt-1 text-center">
      <span
        class="font-bold text-white text-4xl capitalize"
        :class="[paymentPackage.is_highlight ? 'text-white' : '']"
        >{{ paymentPackage.amount }} {{ currency }}</span
      >
      <span
        :class="[paymentPackage.is_highlight ? 'text-white' : 'text-white']"
      >
        / {{ periodInMonth }}
        {{ $tc('price_table.month', periodInMonth) }}</span
      >
        <br/>
      <span class="ml-3 text-2xl text-white" v-if="paymentPackage.price_before > 0"
        >{{ $tc('price_table.price_before')}}: <span class="line-through text-red-600">{{ paymentPackage.price_before }} {{ currency }}</span
      > </span>
    </p>
    <div class="w-full text-center mt-3">
      <h2>{{ periodInMonth }} {{ $tc('price_table.month', periodInMonth) }}</h2>
      <h3 class="text-lg uppercase">{{ packageName }}</h3>
    </div>
    <div class="w-full text-center mt-3">
      <span class="text-lg capitalize"
        >{{ paymentPackage.amount }} {{ currency }}</span
      >
      -
      <span class="text-lg uppercase" v-if="paymentPackage.is_recurring">
        {{ $t('price_table.monthly') }}</span
      >
      <span class="text-lg uppercase" v-else>
        {{ $t('price_table.one_time') }}</span
      >
    </div>

    <div class="text-sm mt-4"></div>
    <button
      class="w-full text-black border rounded-xl transition duration-150 ease-in-out py-4 mt-4 shadow"
      :class="[
        paymentPackage.is_highlight
          ? 'border-pink-600 bg-pink-600 opacity-75 hover:opacity-100 hover:bg-pink-500 hover:shadow-xl'
          : 'border-blue-300 bg-blue-300 hover:border-pink-500 hover:bg-pink-500 hover:text-white hover:shadow-xl',
      ]"
      @click="goToPaySite"
    >
      {{ $t('price_table.choose_plan') }}
    </button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, PropType, toRefs } from 'vue'
import PaymentPackages from '@/types/PaymentPackages'
import { paymentUrlService } from '@/services/PaymentUrl.Service'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'PriceTable',
  props: {
    paymentPackage: {
      type: Object as PropType<PaymentPackages>,
    },
    count: {
      type: Number,
    },
  },
  setup(props) {
    const { paymentPackage } = toRefs(props)
    const { count } = toRefs(props)
    let periodInMonth: number
    let currency: string
    let paymentUrl: string
    const store = useStore()
    const userData = computed(() => store.state.auth.user)
    let userLanguage: string
    let userName: string

    onBeforeMount(async () => {
      await getPaymentUrlData()
    })

    const getPaymentUrlData = async () => {
      await paymentUrlService
        .getPaymentUrl(
          paymentPackage.value['id'],
          paymentPackage.value['epoch_pi_code'],
        )
        .then((response) => {
          paymentUrl = response.data.data.url
        })
        .catch((error_response) => {
          console.error(error_response)
        })
    }

    if (userData.value.language && !userData.value.language.includes('-')) {
      userLanguage = userData.value.language
    } else if (
      userData.value.language &&
      userData.value.language.includes('-')
    ) {
      const languageSplitString = userData.value.language.split('-')
      userLanguage = languageSplitString[0]
    }

    if (userData.value.name) {
      userName = userData.value.name
    }

    const packageInfos = JSON.parse(paymentPackage.value['price_info'])
    const packageName = packageInfos[userLanguage]

    periodInMonth = Math.floor(paymentPackage.value['period'] / 30)
    if (paymentPackage.value['currency'] === 'EUR') {
      currency = '€'
    } else {
      currency = '$'
    }

    const goToPaySite = () => {
      window.location.href = paymentUrl
    }

    return {
      periodInMonth,
      currency,
      packageName,
      userName,
      goToPaySite,
    }
  },
})
</script>

<style scoped></style>
