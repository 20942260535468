import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full md:pb-44" }
const _hoisted_2 = { class: "px-4 xl:px-12 relative z-10 space-y-12 mt-20" }
const _hoisted_3 = { class: "space-y-2" }
const _hoisted_4 = { class: "xl:px-10" }
const _hoisted_5 = { class: "text-xl font-medium font-netflix_medium" }
const _hoisted_6 = { class: "flex flex-wrap items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeriesCard = _resolveComponent("SeriesCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('member_area.series_pl')), 1),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.seriesData, (serie) => {
              return (_openBlock(), _createBlock(_component_SeriesCard, {
                serie: serie,
                key: serie.id
              }, null, 8, ["serie"]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}