<script lang="ts">
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'Footer',
  setup() {
    const year = ref(new Date().getFullYear())
    return {
      year,
    }
  },
})
</script>
<template>
  <div class="w-full bg-black pb-5">
    <div
      class="flex flex-col sm:flex-row sm:justify-center mx-auto px-4 sm:px-6 lg:px-0 lg:py-1 bg-black lg:max-w-8xl"
    >
      <div
        class="flex flex-col sm:flex-row sm:justify-center mx-auto px-4 sm:px-6 lg:px-0 lg:py-1 bg-black lg:max-w-8xl"
      >
        <a
          target="_blank"
          class="sm:py-5 sm:px-3 text-gray-500 hover:text-pink-500 mt-4"
          href="https://www.sextermedia.com/en/site/gtc"
        >
          GTC
        </a>
        <a
          target="_blank"
          class="sm:py-5 sm:px-3 text-gray-500 hover:text-pink-500 mt-4"
          href="https://www.sextermedia.com/en/site/privacy-policy"
        >
          Privacy Policy
        </a>
        <a
          target="_blank"
          class="sm:py-5 sm:px-3 text-gray-500 hover:text-pink-500 mt-4"
          href="https://www.sextermedia.com/en/site/fsk-18-youth-protection"
        >
          FSK 18 youth protection
        </a>
        <a
          target="_blank"
          class="sm:py-5 sm:px-3 text-gray-500 hover:text-pink-500 mt-4"
          href="https://www.sextermedia.com/en/site/service-help"
        >
          Service / Help
        </a>
        <a
          target="_blank"
          class="sm:py-5 sm:px-3 text-gray-500 hover:text-pink-500 mt-4"
          href="https://epoch.com/billing_support"
        >
          Billing Support Epoch
        </a>
        <a
          target="_blank"
          class="sm:py-5 sm:px-3 text-gray-500 hover:text-pink-500 mt-4"
          href="https://www.sextermedia.com/en/report"
        >
          Content Removal Request
        </a>
        <a
          target="_blank"
          class="sm:py-5 sm:px-3 text-gray-500 hover:text-pink-500 mt-4"
          href="https://www.sextermedia.com/en/contact-support"
        >
          Contact / Support
        </a>
      </div>
    </div>
  </div>
</template>
