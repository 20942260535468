import axios from 'axios'

class PaymentUrlService {
  async getPaymentUrl(
    package_id: number,
    epoch_pi_code: string,
    campaign_id?: string,
  ) {
    if (!campaign_id) {
      return await axios.get(
        `api/payment_url?package_id=${package_id}&epoch_pi_code=${epoch_pi_code}`,
      )
    }
    return await axios.get(
      `api/payment_url?package_id=${package_id}&epoch_pi_code=${epoch_pi_code}&campaign_id=${campaign_id}`,
    )
  }
}

export const paymentUrlService = new PaymentUrlService()
