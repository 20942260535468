import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f65134a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "flex items-center space-x-2" }
const _hoisted_3 = { class: "line-clamp-1" }
const _hoisted_4 = { class: "flex justify-between items-center space-x-2 text-xs" }
const _hoisted_5 = { class: "text-yellow-500" }
const _hoisted_6 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_IconPlaySolid = _resolveComponent("IconPlaySolid")!
  const _component_circle_button = _resolveComponent("circle-button")!
  const _component_IconThumbLike = _resolveComponent("IconThumbLike")!
  const _component_IconThumbDislike = _resolveComponent("IconThumbDislike")!
  const _component_IconBxCalendar = _resolveComponent("IconBxCalendar")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "container",
    class: _normalizeClass(["cursor-pointer video-card relative transition-all duration-300 video-card-pt", {
      'animate-card-hover': _ctx.isScaled,
      'animate-card-unhover': !_ctx.isScaled,
    }]),
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMouseEnter && _ctx.handleMouseEnter(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args)))
  }, [
    _createVNode(_component_Image, {
      src: 
        _ctx.videoData.videos_sets_preview[0].video_set_preview_image +
        '/' +
        _ctx.videoData.slug +
        '/340x0/' +
        _ctx.videoData.videos_sets_preview[0].image_name
      ,
      class: _normalizeClass({ 'rounded-b-none shadow': _ctx.isScaled }),
      onClick: _ctx.handleClick
    }, null, 8, ["src", "class", "onClick"]),
    (_ctx.isMouseEnter && _ctx.isDesktop)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["absolute top-full w-full h-26 bg-background rounded-b-md transition-all duration-300 shadow p-3 space-y-2", [!_ctx.isScaled ? 'invisible opacity-0' : 'visible opacity-100']])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_circle_button, {
                class: "p-1 bg-white text-black border-white hover:bg-gray-300",
                onClick: _ctx.handlePlayClick
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconPlaySolid)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_circle_button, {
                class: "p-1",
                onClick: _withModifiers(_ctx.setLike, ["prevent"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconThumbLike)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_circle_button, {
                class: "p-1",
                onClick: _withModifiers(_ctx.setDislike, ["prevent"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconThumbDislike)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.videoTitle), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, "Likes: " + _toDisplayString(_ctx.likes), 1),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_IconBxCalendar),
              _createTextVNode(_toDisplayString(_ctx.publishOn), 1)
            ])
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 34))
}