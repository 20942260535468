<template>
  <div
    class="header top-0 fixed z-50 w-full h-16 transition duration-700"
    :class="[!isTop && 'bg-black']"
  >
    <div class="header__overlay absolute inset-0 w-full h-full"></div>
    <div
      class="relative z-10 flex items-center justify-between w-full h-full p-5 px-4 md:px-12"
    >
      <MobileNav class="lg:hidden" />
      <div class="flex space-x-4">
        <div class="">
          <router-link :to="{ name: 'dashboard' }">
            <img
              class="h-full object-cover ml-4"
              src="../assets/sextermedia-logo.png"
            />
          </router-link>
        </div>
        <div class="hidden lg:flex items-center space-x-1">
          <router-link
            :to="{ name: 'dashboard' }"
            class="py-5 px-3 hover:text-pink-500"
            :class="[
              $route.name === 'dashboard' ? 'text-pink-500' : 'text-gray-50',
            ]"
            >{{ $t('header.nav.dashboard') }}</router-link
          >
          <router-link
            :to="{ name: 'series' }"
            class="py-5 px-3 hover:text-pink-500"
            :class="[
              $route.name === 'series' ? 'text-pink-500' : 'text-gray-50',
            ]"
            >{{ $t('header.nav.series') }}</router-link
          >
          <router-link
            :to="{ name: 'actors' }"
            class="py-5 px-3 hover:text-pink-500"
            :class="[
              $route.name === 'actors' ? 'text-pink-500' : 'text-gray-50',
            ]"
            >{{ $t('header.nav.actors') }}</router-link
          >
          <router-link
            :to="{ name: 'user_favorites' }"
            class="py-5 px-3 hover:text-pink-500"
            :class="[
              $route.name === 'user_favorites'
                ? 'text-pink-500'
                : 'text-gray-50',
            ]"
            >{{ $t('header.nav.favorites') }}</router-link
          >
        </div>
      </div>
      <div class="hidden lg:flex items-center space-x-1">
        <button
          @click="dropdownOpen = !dropdownOpen"
          class="relative z-10 block h-8 w-8 rounded-full overflow-hidden shadow focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>

        <div
          v-show="dropdownOpen"
          @click="dropdownOpen = false"
          class="fixed inset-0 h-full w-full z-10"
        ></div>

        <div
          v-show="dropdownOpen"
          class="absolute right-0 mt-2 py-2 w-48 rounded-md shadow-xl z-20"
        >
          <a
            href="#"
            @click.prevent="logout"
            class="block px-4 py-2 text-sm text-gray-50 hover:text-pink-500"
            >Log out</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import MobileNav from './atoms/MobileNav.vue'
import { SUPPORTED_LOCALES } from '@/config/i18n'

export default defineComponent({
  name: 'Header',
  setup() {
    const isTop = ref<boolean>(true)
    const dropdownOpen = ref<boolean>(false)
    const path = '/'
    const locale = SUPPORTED_LOCALES
    return {
      dropdownOpen,
      isTop,
      path,
      locale,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
      logoutAction: 'auth/logoutAction',
    }),
    async logout() {
      await this.logoutAction().then(() => {
        this.$router.push({ name: 'login' })
      })
    },
    handleScroll(event) {
      this.isTop = event.target.scrollTop === 0
    },
  },
  components: { MobileNav },
})
</script>
<style scoped>
.navbar-fixed {
  top: 0;
}
.header__overlay {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(10%, rgba(0, 0, 0, 0.7)),
    color-stop(10%, rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.7) 10%,
    rgba(0, 0, 0, 0)
  );
  background-image: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.7) 10%,
    rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7) 10%,
    rgba(0, 0, 0, 0)
  );
}
</style>
