import axios from 'axios'

class VideosSetsService {
  async getVideosSets(
    perPage: number = null,
    page: number | string | string[] = null,
    search: string = null,
  ) {
    if (search) {
      return await axios.get(
        `api/videos-sets?per_page=${perPage}&page=${page}&search=${search}`,
      )
    }
    return axios.get(`api/videos-sets?per_page=${perPage}&page=${page}`)
  }

  async getTopVideo(perPage: number = null) {
    return axios.get(`api/top-videos-sets?per_page=${perPage}`)
  }

  async getTopLiked(perPage: number = null) {
    return axios.get(`api/top-liked-videos-sets?per_page=${perPage}`)
  }
  async getHighlightsVideos(perPage: number = null) {
    return axios.get(`api/highlights-videos-sets?per_page=${perPage}`)
  }

  async getSpecialVideos(perPage: number = null) {
    return axios.get(`api/specials-videos-sets?per_page=${perPage}`)
  }

  async getBannerVideo() {
    return await axios.get('api/banner-video')
  }
  async sendLike(userId: string, videoId: number | string | string[]) {
    return await axios.post(`api/content-like/${userId}?video_set=${videoId}`)
  }
  async sendDisLike(userId: string, videoId: number | string | string[]) {
    return await axios.delete(
      `api/content-dislike/${userId}?video_set=${videoId}`,
    )
  }
  async getVideoData(videoId: number) {
    return await axios.get(`api/video-data/${videoId}`)
  }
  async getVideoUrl(videoId: number | string | string[]) {
    return await axios.get(`api/video-url/${videoId}`)
  }

  async updateVideoViews(videoId: number | string | string[]) {
    return await axios.put(`api/video-view-update/${videoId}`)
  }

  async addToFavorites(userId: string, videoId: number | string | string[]) {
    return await axios.post(`api/user-favorites/${userId}?video_set=${videoId}`)
  }

  async removeToFavorites(userId: string, videoId: number | string | string[]) {
    return await axios.delete(
      `api/user-favorites/${userId}?video_set=${videoId}`,
    )
  }
}

export const videosSetsService = new VideosSetsService()
