<template>
  <div class="min-h-screen">
    <Header />
    <div class="min-h-screen">
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut"
        mode="out-in"
      >
        <slot />
      </transition>
    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default defineComponent({
  name: 'DashboardLayout',
  components: {
    Footer,
    Header,
  },
  setup() {
    //set to 1
    window.scrollTo(0, 1)
    let scrollTop = 1
    window.addEventListener('load', () => {
      setTimeout(() => {
        scrollTop =
          'scrollTop' in window.document.body
            ? window.document.body.scrollTop
            : 1
        window.scrollTo(0, scrollTop === 1 ? 0 : 1)
      }, 0)
    })
  },
})
</script>
