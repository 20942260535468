<template>
  <LoadingSpinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#ed64a6"
    :size="60"
  ></LoadingSpinner>
  <div class="w-full h-full md:pb-44">
    <div class="px-4 xl:px-12 relative z-10 space-y-12 mt-20">
      <div class="space-y-2">
        <div class="xl:px-10">
          <h1 class="text-xl font-medium font-netflix_medium">
            {{ $t('member_area.series_si') }} >>
            <span class="italic underline decoration-1">{{ seriesTitle }}</span>
            {{ $t('member_area.images') }}
          </h1>
          <div
            class="flex flex-wrap items-center justify-center"
            ref="scrollComponent"
          >
            <SeriesImagesCard
              :imageSet="imageSet"
              v-for="imageSet in seriesImages"
              :key="imageSet.id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
} from 'vue'
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import SeriesImagesCard from '@/components/SeriesImagesCard.vue'
import { useRoute, useRouter } from 'vue-router'
import SeriesImages from '@/types/SeriesImages'

import { seriesDataService } from '@/services/SeriesData.Service'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'SeriesImages',
  components: { LoadingSpinner, SeriesImagesCard },
  setup() {
    const loadingSpinner = ref(null)
    const scrollComponent = ref(null)
    const store = useStore()
    const userData = computed(() => store.state.auth.user)
    let userLanguage: string
    const seriesTitle = ref<string>('')
    const router = useRouter()
    const route = useRoute()
    const serie_id = route.params.serie_id
    const seriesImages = ref<SeriesImages[]>([])
    let page = 1
    let lastPage: number
    let perPage = 10
    if (!userData.value.language.includes('-')) {
      userLanguage = userData.value.language
    } else {
      const languageSplitString = userData.value.language.split('-')
      userLanguage = languageSplitString[0]
    }
    onBeforeMount(async () => {
      await getSeriesImages()
      await getSeriesTitle()
    })

    onMounted(() => {
      window.addEventListener('scroll', handleSeriesImagesScrolling, true)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleSeriesImagesScrolling, true)
    })

    const getSeriesImages = async () => {
      await seriesDataService
        .getSeriesImagesSets(perPage, page, serie_id)
        .then((response) => {
          lastPage = response.data.meta.last_page
          seriesImages.value = response.data.data
        })
        .catch((error) => {
          console.log(error)
          if (
            error.response.status === 403 &&
            error.response.statusText === 'Forbidden'
          ) {
            router.push({ name: 'forbidden' })
          }
        })
    }
    const getSeriesTitle = async () => {
      for (let key in seriesImages.value[0].series.series_descriptions) {
        if (
          seriesImages.value[0].series.series_descriptions[key].language ===
          userLanguage
        ) {
          seriesTitle.value =
            seriesImages.value[0].series.series_descriptions[key].title
        }
      }
    }

    const loadMoreSeriesImages = async () => {
      page++
      if (!(page > lastPage)) {
        loadingSpinner.value.open()

        await seriesDataService
          .getSeriesImagesSets(perPage, page, serie_id)
          .then((response) => {
            lastPage = response.data.meta.last_page
            seriesImages.value.push(...response.data.data)
            loadingSpinner.value.close()
          })
          .catch((error) => {
            console.log(error)
            if (
              error.response.status === 403 &&
              error.response.statusText === 'Forbidden'
            ) {
              router.push({ name: 'forbidden' })
            }
          })
      }
    }
    const handleSeriesImagesScrolling = () => {
      let element = scrollComponent.value
      if (element.getBoundingClientRect().bottom < window.innerHeight) {
        loadMoreSeriesImages()
      }
    }

    return {
      loadingSpinner,
      seriesTitle,
      seriesImages,
      scrollComponent,
    }
  },
})
</script>

<style scoped></style>
