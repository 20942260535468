import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full md:pb-44" }
const _hoisted_2 = { class: "px-4 xl:px-12 relative z-10 space-y-12 mt-20" }
const _hoisted_3 = { class: "space-y-2" }
const _hoisted_4 = { class: "xl:px-10" }
const _hoisted_5 = { class: "flex flex-wrap items-center md:pl-10 lg:pl-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_video_player = _resolveComponent("video-player")!
  const _component_ActorDetailsModal = _resolveComponent("ActorDetailsModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_video_player)
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_Transition, {
      "enter-active-class": "animate__animated animate__fadeIn",
      "leave-active-class": "animate__animated animate__fadeOut"
    }, {
      default: _withCtx(() => [
        (_ctx.isActorsDetailModalActive)
          ? (_openBlock(), _createBlock(_component_ActorDetailsModal, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}