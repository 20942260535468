import auth from './auth'
import { createStore } from 'vuex'

export default createStore({
  mutations: {},
  actions: {},
  modules: {
    auth,
  },
})
