import { reactive, readonly } from 'vue'
import LightBoxData from '@/types/LightBoxData'

const initialState = reactive({
  isLightBoxActive: false,
  lightBoxData: {},
})

export const setLightBoxActive = (isActive: boolean): void => {
  initialState.isLightBoxActive = isActive
}
export const setLightBoxData = (data: LightBoxData): void => {
  initialState.lightBoxData = data
}

export const state = readonly(initialState)
