<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="img"
    width="1em"
    height="1em"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 20 20"
  >
    <path fill="currentColor" d="m6 15l5-5l-5-5l1-2l7 7l-7 7z" />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'IconArrowRightAlt2',
})
</script>

<style scoped></style>
