<template>
  <LoadingSpinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#ed64a6"
    :size="60"
  ></LoadingSpinner>
  <div
    class="z-60 bg-black bg-opacity-60 top-0 fixed h-screen w-full overflow-y-scroll"
    @click.self="handleCloseClick"
  >
    <div class="info-container">
      <div>
        <div class="relative w-full h-[30rem]">
          <Image :src="imageLink" class="w-full h-full object-cover" />

          <circle-button
            class="z-50 absolute top-5 right-5 bg-background text-white"
            @click="handleCloseClick"
          >
            <IconWindowCloseLine class="text-lg" />
          </circle-button>

          <div class="info__overlay">
            <h1 class="text-2xl font-netflix_medium mb-6">{{ actorAlias }}</h1>
          </div>
        </div>

        <div class="space-y-8 px-4 md:px-12 my-3">
          <div class="md:space-x-8 flex flex-col md:flex-row space-y-4">
            <div class="flex-1 flex-grow-[2] space-y-4 hidden md:block">
              <p class="text-base text-gray-300"></p>
              <p class="text-base">{{ actorsDescription }}</p>
            </div>

            <div class="mt-4 md:mt-0 flex-1 space-y-2 text-sm">
              <div class="space-x-2">
                <span class="text-gray-400">Mein Steckbrief:</span>
              </div>
              <div class="space-x-2">
                <span class="text-gray-400">
                  {{ $t('actor_details.age') }}:</span
                >
                <span class="text-white">{{ age }}</span>
              </div>
              <div class="space-x-2">
                <span class="text-gray-400"
                  >{{ $t('actor_details.weight') }}:</span
                >
                <span class="text-white">{{ weight }} kg</span>
              </div>
              <div class="space-x-2">
                <span class="text-gray-400"
                  >{{ $t('actor_details.cup_size') }}:</span
                >
                <span class="text-white">{{ cup_size }}</span>
              </div>
              <div class="space-x-2">
                <span class="text-gray-400"
                  >{{ $t('actor_details.height') }}:</span
                >
                <span class="text-white">{{ height }} cm</span>
              </div>
              <div class="space-x-2">
                <span class="text-gray-400"
                  >{{ $t('actor_details.nationality') }}:</span
                >
                <span class="text-white">{{
                  $t('actor_details.nationality_names.' + nationality)
                }}</span>
              </div>
              <div class="space-x-2">
                <span class="text-gray-400"
                  >{{ $t('actor_details.zodiac') }}:</span
                >
                <span class="text-white">{{
                  $t('actor_details.zodiac_names.' + zodiac)
                }}</span>
              </div>
              <div class="space-x-2">
                <span class="text-gray-400"
                  >{{ $t('actor_details.genital_area') }}:</span
                >
                <span class="text-white">{{
                  $t('actor_details.genital_area_text.' + genital_area)
                }}</span>
              </div>
              <div class="space-x-2">
                <span class="text-gray-400"
                  >{{ $t('actor_details._preferences') }}:</span
                >
                <div class="flex flex-wrap">
                  <span
                    class="text-white bg-gray-500 px-2 m-1 rounded-sm"
                    v-for="item in preferences"
                    :key="item"
                  >
                    {{ $t('actor_details.preferences.' + item) }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <h2 class="text-base font-netflix_medium">Preview Image</h2>
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div
              v-for="(item, key) in previewImages"
              :key="key"
              class="relative"
            >
              <div class="relative">
                <Image :src="item" class="w-full h-full object-cover" />
              </div>
            </div>
          </div>
          <h2 class="text-base font-netflix_medium">Actor content</h2>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 pb-5"
          >
            <div v-for="(contentItem, key) in actorsContent" :key="key">
              <div class="relative cursor-pointer">
                <Image
                  :src="contentItem.content_preview"
                  class="w-full h-full object-cover"
                />
                <div
                  class="opacity-100 transition-all duration-300 absolute left-1/3 top-1/3 rounded-full p-4 bg-black bg-opacity-80 border border-gray-300 cursor-pointer"
                  @click="
                    handlePlayClick(
                      contentItem.content_id,
                      contentItem.content_type,
                    )
                  "
                  v-touch:tap="
                    handlePlayClick(contentItem.content_id, contentItem.c)
                  "
                >
                  <IconPlaySolid
                    class="text-xl text-wihte"
                    v-if="contentItem.content_type === 'videos_sets'"
                  />
                  <IconImagesAlt
                    class="text-xl"
                    v-if="contentItem.content_type === 'images_sets'"
                  />
                </div>
              </div>

              <div class="rounded-b-md bg-color p-4">
                <h3
                  v-if="contentItem.content_type === 'videos_sets'"
                  class="text-sm"
                >
                  {{ getContentTitle(contentItem.titles) }}
                </h3>
                <h3
                  v-if="contentItem.content_type === 'images_sets'"
                  class="text-sm"
                >
                  {{ getContentTitle(contentItem.titles) }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import CircleButton from '@/components/atoms/CircleButton.vue'
import Image from '@/components/atoms/Image.vue'
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import {
  state,
  setActorsDetailModalActive,
  setIsVideoPlayer,
  setIsNewGallery,
} from '@/store/ActorModal'
import { actorsService } from '@/services/Actors.Service'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import IconImagesAlt from '@/components/atoms/IconImagesAlt.vue'
import IconPlaySolid from '@/components/atoms/IconPlaySolid.vue'
import IconWindowCloseLine from '@/components/atoms/IconWindowCloseLine.vue'

export default defineComponent({
  name: 'ActorDetailsModal',
  components: {
    IconWindowCloseLine,
    IconImagesAlt,
    IconPlaySolid,
    CircleButton,
    Image,
    LoadingSpinner,
  },
  setup() {
    const loadingSpinner = ref(null)
    const handleCloseClick = () => {
      setActorsDetailModalActive(false)
    }

    const imageLink = ref<string>(null)
    const actorAlias = ref<string>(null)
    const actorsDescription = ref<string>(null)
    const previewImages = ref<string[]>([])
    const preferences = ref<string[]>([])
    const actorId = state.actorData
    const age = ref<string>(null)
    const height = ref<string>(null)
    const cup_size = ref<string>(null)
    const weight = ref<string>(null)
    const zodiac = ref<string>(null)
    const genital_area = ref<string>(null)
    const nationality = ref<string>(null)
    const router = useRouter()
    const actorsContent = ref<string[] | number[]>([])
    const user_lang = ref<string>(null)

    const store = useStore()
    const userData = computed(() => store.state.auth.user)
    let userLanguage: string
    if (!userData.value.language.includes('-')) {
      userLanguage = userData.value.language
    } else {
      const languageSplitString = userData.value.language.split('-')
      userLanguage = languageSplitString[0]
    }
    user_lang.value = userLanguage

    onBeforeMount(async () => {
      await actorsService
        .getActor(actorId)
        .then((response) => {
          const data = response.data.data
          imageLink.value =
            data.actors_preview_images[0].actor_preview_url +
            '/' +
            data.slug +
            '/680x454/' +
            data.actors_preview_images[0].image_name
          actorAlias.value = data.alias
          age.value = data.age
          height.value = data.height
          cup_size.value = data.cup_size
          weight.value = data.weight
          zodiac.value = data.zodiac
          genital_area.value = data.genital_area
          nationality.value = data.nationality
          actorsContent.value = data.actors_content

          for (let key in data.actors_descriptions) {
            if (data.actors_descriptions[key].language === userLanguage) {
              actorsDescription.value =
                data.actors_descriptions[key].description
            }
          }
          preferences.value = data.preferences
          previewImages.value = data.actors_preview_images
            .filter((item, idx) => idx < 6)
            .map((item) => {
              return (
                item.actor_preview_url +
                '/' +
                data.slug +
                '/340x227/' +
                item.image_name
              )
            })
        })
        .catch((error) => {
          console.error(error)
        })
    })

    const getContentTitle = (titles: Record<string, string>[]): string => {
      for (let key in titles) {
        if (userLanguage in titles[key]) {
          return titles[key][userLanguage]
        }
      }
      return null
    }

    const handlePlayClick = (id: number, content_type: string) => {
      if (content_type === 'videos_sets') {
        setIsVideoPlayer(false)
        setActorsDetailModalActive(false)
        router.push({ name: 'video', params: { video_id: id } })
      }
      if (content_type === 'images_sets') {
        setActorsDetailModalActive(false)
        setIsNewGallery(true)
        router.push({ name: 'gallery', params: { image_set_id: id } })
      }
    }

    return {
      loadingSpinner,
      handleCloseClick,
      imageLink,
      actorAlias,
      previewImages,
      actorsDescription,
      preferences,
      age,
      height,
      cup_size,
      weight,
      zodiac,
      genital_area,
      nationality,
      user_lang,
      actorsContent,
      handlePlayClick,
      getContentTitle,
    }
  },
})
</script>

<style scoped>
.bg-color {
  background: #2f2f2f;
}
.info-container {
  @apply bg-background md:rounded-xl lg:w-2/3 md:w-4/5  w-full max-w-4xl min-h-screen md:mt-2 shadow overflow-hidden mx-auto;
  -webkit-box-shadow: 0px 0px 12px 0px #000000;
  box-shadow: 0px 0px 12px 0px #000000;
}
.info__overlay {
  @apply p-4 md:p-12 absolute top-0 inset-0 flex flex-col justify-end space-y-2;
  background: linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(var(--background-color-rgb), 1)
  );
}
</style>
