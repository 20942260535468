import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-28348460"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative transition-all duration-300 md:max-w-xs w-full my-3 xl:mx-3 mx-1 serie-card" }
const _hoisted_2 = { class: "items-center rounded-t-md" }
const _hoisted_3 = { class: "bg-color items-center w-full" }
const _hoisted_4 = { class: "p-4 h-60 w-full bg-color space-y-2 rounded-b-md" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "line-clamp-1" }
const _hoisted_7 = { class: "text-sm text-gray-400 line-clamp-6" }
const _hoisted_8 = { class: "flex justify-between items-center space-x-2 text-xs" }
const _hoisted_9 = { class: "text-bold" }
const _hoisted_10 = { class: "text-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_IconVideoAlt3 = _resolveComponent("IconVideoAlt3")!
  const _component_circle_button = _resolveComponent("circle-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_IconImagesAlt = _resolveComponent("IconImagesAlt")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Image, {
        src: _ctx.serie.logo
      }, null, 8, ["src"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Image, {
        src: _ctx.serie.video_set_preview_image
      }, null, 8, ["src"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.description), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_router_link, {
          to: { name: 'series_videos', params: { serie_id: _ctx.serie_id } }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_circle_button, {
              class: "ml-3 p-1",
              onClick: _ctx.handleClickOnImage
            }, {
              default: _withCtx(() => [
                _createVNode(_component_IconVideoAlt3, { class: "text-xl" })
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createElementVNode("p", _hoisted_9, "Videos: " + _toDisplayString(_ctx.serie.videos_sets_count), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        _createVNode(_component_router_link, {
          to: { name: 'series_images', params: { serie_id: _ctx.serie_id } }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_circle_button, {
              class: "ml-4 p-1",
              onClick: _ctx.handleClickOnImage
            }, {
              default: _withCtx(() => [
                _createVNode(_component_IconImagesAlt, { class: "text-xl" })
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createElementVNode("p", _hoisted_10, "Galerien: " + _toDisplayString(_ctx.serie.images_sets_count), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ])
  ]))
}