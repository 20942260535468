import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f130c1e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-container flex flex-row" }
const _hoisted_2 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconArrowLeftAlt2 = _resolveComponent("IconArrowLeftAlt2")!
  const _component_Image = _resolveComponent("Image")!
  const _component_IconWindowCloseLine = _resolveComponent("IconWindowCloseLine")!
  const _component_circle_button = _resolveComponent("circle-button")!
  const _component_IconArrowRightAlt2 = _resolveComponent("IconArrowRightAlt2")!

  return (_openBlock(), _createElementBlock("div", {
    class: "z-60 bg-black bg-opacity-80 top-0 fixed h-screen w-full overflow-y-scroll flex",
    onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.handleCloseClick()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isDesktop)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "nav-button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlePrevious && _ctx.handlePrevious(...args)))
          }, [
            _createVNode(_component_IconArrowLeftAlt2, { class: "text-9xl text-white" })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["image-wraper", {
          'animate-card-hover': _ctx.isScaled,
          'animate-card-unhover': !_ctx.isScaled,
        }])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Image, {
            src: _ctx.image,
            class: "w-full h-full md:rounded-xl",
            onTouchstart: _ctx.handleTouchstart,
            onTouchmove: _ctx.handleSwipeEvent,
            onTouchend: _ctx.handleTouchend,
            onContextmenu: _ctx.handleRightClick
          }, null, 8, ["src", "onTouchstart", "onTouchmove", "onTouchend", "onContextmenu"]),
          (_ctx.isDesktop)
            ? (_openBlock(), _createBlock(_component_circle_button, {
                key: 0,
                class: "z-50 absolute top-5 right-5 bg-background text-white",
                onClick: _ctx.handleCloseClick
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IconWindowCloseLine, { class: "text-2xl" })
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ], 2),
      (_ctx.isDesktop)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "nav-button",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleNext && _ctx.handleNext(...args)))
          }, [
            _createVNode(_component_IconArrowRightAlt2, { class: "text-9xl text-white" })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}