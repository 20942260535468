<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div
    class="bg-cover h-screen px-6"
    style="
      background-image: url('https://images.sextermedia.com/content/images/background/0x0/sextermedia_background.webp');
    "
  >
    <div class="flex pt-4 pl-4">
      <img src="../assets/sextermedia-logo.png" />
    </div>
    <div class="flex justify-center items-center h-3/4">
      <div class="p-6 max-w-sm w-full bg-black shadow-md rounded-md">
        <div class="flex justify-center items-center">
          <span class="text-white font-semibold text-2xl">{{
            $t('member_form.form.register_title')
          }}X</span>
        </div>
        <div class="mt-1 justify-center items-center w-full" v-if="error">
          <span
            v-for="(error_data, key) in error"
            :key="key"
            class="flex w-full text-red-500 font-medium"
          >
            {{ error_data[0] }}
          </span>
        </div>
        <form class="mt-4" @submit.prevent="register">
          <div class="mb-6">
            <label for="email" class="inline-block text-sm mb-2"
              >{{ $t('member_form.form.label_name_text') }}:
            </label>
            <input
              type="text"
              id="name"
              class="w-full h-10 bg-gray-800 text-white px-3 rounded-sm"
              v-model="form.name"
            />
            <span v-if="v$.form.name.$error" class="text-red-600">
              <p v-for="(error, key) in v$.form.name.$errors" :key="key">
                {{ error.$message }}
              </p>
            </span>
          </div>
          <div class="mb-6">
            <label for="email" class="inline-block text-sm mb-2">
              {{ $t('member_form.form.label_email_text') }}:
            </label>
            <input
              type="email"
              id="email"
              class="w-full h-10 bg-gray-800 text-white px-3 rounded-sm"
              v-model="form.email"
            />
            <span v-if="v$.form.email.$error" class="text-red-600">
              <p v-for="(error, key) in v$.form.email.$errors" :key="key">
                {{ error.$message }}
              </p>
            </span>
          </div>
          <div class="mb-6">
            <label for="password" class="inline-block text-sm mb-2">
              {{ $t('member_form.form.label_password_text') }}:
            </label>
            <input
              type="password"
              id="password"
              class="w-full h-10 bg-gray-800 text-white px-3 rounded-sm"
              v-model="form.password"
            />
            <span v-if="v$.form.password.$error" class="text-red-600">
              <p v-for="(error, key) in v$.form.password.$errors" :key="key">
                {{ error.$message }}
              </p>
            </span>
          </div>
          <div class="mb-6">
            <label for="password" class="inline-block text-sm mb-2">
              {{ $t('member_form.form.label_password_confirmation_text') }}:
            </label>
            <input
              type="password"
              id="password_confirmation"
              class="w-full h-10 bg-gray-800 text-white px-3 rounded-sm"
              v-model="form.password_confirmation"
            />
            <span
              v-if="v$.form.password_confirmation.$error"
              class="text-red-600"
            >
              <p
                v-for="(error, key) in v$.form.password_confirmation.$errors"
                :key="key"
              >
                {{ error.$message }}
              </p>
            </span>
          </div>
          <div class="mt-6">
            <button
              type="submit"
              class="py-2 px-4 text-center bg-pink-700 rounded-md w-full text-white text-sm hover:bg-pink-800"
              :disabled="v$.$invalid"
            >
              {{ $t('member_form.form.register_button_text') }}
            </button>
          </div>
          <div class="mt-5">
            {{ $t('member_form.form.user_has_account') }}
            <router-link :to="{ name: 'login' }"
              >{{ $t('member_form.form.login_link_text') }}
            </router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue'
import { registerService } from '@/services/Register.Service'
import RegisterData from '@/types/RegisterData'
import useVuelidate from '@vuelidate/core'
import {
    alphaNum,
    email,
    minLength,
    required,
    sameAs,
} from '@vuelidate/validators'
import { computed, defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Register',
  components: {
    LoadingSpinner,
  },
  setup() {
    const router = useRouter()
    const loadingSpinner = ref(null)
    const form = ref<RegisterData>({
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      language: '',
    })
    let error = ref<string[]>(null)

    const state = reactive({
      form,
    })
    const rules = computed(() => {
      return {
        form: {
          name: { required, minLengthValue: minLength(4), $autoDirty: true },
          email: { required, email, $autoDirty: true },
          password: {
            required,
            minLengthValue: minLength(10),
            alphaNum,
            $autoDirty: true,
          },
          password_confirmation: {
            required,
            sameAs: sameAs(form.value.password),
            $autoDirty: true,
          },
        },
      }
    })
    const v$ = useVuelidate(rules, state)

    const register = async () => {
      loadingSpinner.value.open()
      error.value = []
      let navigatorLanguage: string | string[]
      if (navigator.language.includes('-')) {
        navigatorLanguage = navigator.language.split('-')[0]
      } else {
        navigatorLanguage = navigator.language
      }
      form.value.language = navigatorLanguage ?? 'en'
      await registerService
        .registerUser(form.value)
        .then((response) => {
          loadingSpinner.value.close()
          if (response.status === 200) {
            console.log(response.data)
            router.push({ name: 'thankyou' })
          }
        })
        .catch((error_response) => {
          const errorsObject = error_response.response.data
          loadingSpinner.value.close()
          error.value = Object.keys(errorsObject).map((errorIndex) => {
            return errorsObject[errorIndex]
          })
        })
    }
    return {
      loadingSpinner,
      form,
      v$,
      register,
      error,
    }
  },
})
</script>
