<template>
  <div class="w-full h-full md:pb-44">
    <div class="px-4 xl:px-12 relative z-10 space-y-12 mt-20">
      <div class="space-y-2">
        <div class="xl:px-10">
          <div class="flex flex-wrap items-center md:pl-10 lg:pl-0">
            <video-player></video-player>
          </div>
        </div>
      </div>
    </div>
  </div>
  <transition
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
  >
    <ActorDetailsModal v-if="isActorsDetailModalActive" />
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, watch } from 'vue'
import { useRoute } from 'vue-router'
import VideoPlayer from '@/components/VideoPlayer.vue'
import ActorDetailsModal from '@/components/ActorDetailsModal.vue'
import { state } from '@/store/ActorModal'
export default defineComponent({
  name: 'video',
  components: {
    VideoPlayer,
    ActorDetailsModal,
  },
  setup() {
    const videoId = ref<string | string[]>()
    const router = useRoute()
    videoId.value = router.params.video_id

    watch(
      () => state.isActorsDetailModalActive,
      () => {
        setTimeout(function () {
          window.scrollTo({ left: 0, top: state.scrollTop })
        }, 0)
      },
    )

    return {
      videoId,
      ...toRefs(state),
    }
  },
})
</script>

<style scoped></style>
