<template>
  <div
    class="relative transition-all duration-300 md:max-w-xs w-full my-3 xl:mx-3 mx-1 serie-card"
  >
    <div class="items-center rounded-t-md">
      <Image :src="serie.logo" />
    </div>
    <div class="bg-color items-center w-full">
      <Image :src="serie.video_set_preview_image" />
    </div>
    <div class="p-4 h-60 w-full bg-color space-y-2 rounded-b-md">
      <div class="">
        <p class="line-clamp-1">{{ title }}</p>
        <p class="text-sm text-gray-400 line-clamp-6">
          {{ description }}
        </p>
      </div>
      <div class="flex justify-between items-center space-x-2 text-xs">
        <router-link :to="{ name: 'series_videos', params: { serie_id } }">
          <circle-button class="ml-3 p-1" @click="handleClickOnImage">
            <IconVideoAlt3 class="text-xl" />
          </circle-button>
          <p class="text-bold">Videos: {{ serie.videos_sets_count }}</p>
        </router-link>
        <router-link :to="{ name: 'series_images', params: { serie_id } }">
          <circle-button class="ml-4 p-1" @click="handleClickOnImage">
            <IconImagesAlt class="text-xl" />
          </circle-button>
          <p class="text-bold">Galerien: {{ serie.images_sets_count }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, toRefs } from 'vue'
import SeriesPageData from '@/types/SeriesPageData'
import Image from '@/components/atoms/Image.vue'

import CircleButton from './atoms/CircleButton.vue'
import { useStore } from 'vuex'
import IconVideoAlt3 from '@/components/atoms/IconVideoAlt3.vue'
import IconImagesAlt from '@/components/atoms/IconImagesAlt.vue'
export default defineComponent({
  name: 'SeriesCard',
  components: { IconVideoAlt3, IconImagesAlt, Image, CircleButton },
  props: {
    serie: {
      type: Object as PropType<SeriesPageData>,
    },
  },
  setup(props) {
    let { serie } = toRefs(props)
    const store = useStore()
    const userData = computed(() => store.state.auth.user)
    const title = ref<string>('')
    const description = ref<string>('')
    const serie_id = ref<number>()
    let userLanguage: string
    if (userData.value.language && !userData.value.language.includes('-')) {
      userLanguage = userData.value.language
    } else if (
      userData.value.language &&
      userData.value.language.includes('-')
    ) {
      const languageSplitString = userData.value.language.split('-')
      userLanguage = languageSplitString[0]
    }

    for (let key in serie.value['series_descriptions']) {
      if (serie.value['series_descriptions'][key].language === userLanguage) {
        title.value = serie.value['series_descriptions'][key].title
        description.value = serie.value['series_descriptions'][key].description
      }
      serie_id.value = serie.value['id']
    }

    return {
      title,
      description,
      serie_id,
    }
  },
})
</script>

<style scoped>
.bg-color {
  background: #1e1e1e;
}
.serie-card img {
  @apply object-cover rounded-md w-full h-full;
}
</style>
