import axios from 'axios'
import RegisterData from '@/types/RegisterData'

class RegisterService {
  async registerUser(userData: RegisterData) {
    return await axios.post('api/register', userData)
  }
  async verifyEmail(verify_url: string) {
    return await axios.get(verify_url)
  }
  async resendVerifyEmail(userData: RegisterData) {
    return await axios.post('api/email/verify/resend', userData)
  }
}

export const registerService = new RegisterService()
