<template>
  <button
    class="flex items-center justify-center rounded-full p-2 border border-secondary hover:border-gray-300"
  >
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CircleButton',
  methods: {
    handleClick(e) {
      this.$emit('click', e)
    },
  },
})
</script>
