import { reactive, readonly } from 'vue'

const initialState = reactive({
  isModalActive: false,
  scrollTop: 0,
  modalData: null,
})

export const setModalActive = (isActive: boolean): void => {
  // Keep scrollTop
  if (isActive) {
    initialState.scrollTop = document.documentElement.scrollTop
  }

  initialState.isModalActive = isActive
}

export const setModalData = (videoId: number): void => {
  initialState.modalData = videoId
}

export const state = readonly(initialState)
