<template>
  <loading-spinner
    ref="loadingSpinner"
    :animation-duration="1000"
    color="#818CF8"
    :size="60"
  ></loading-spinner>
  <div
    class="bg-cover h-screen px-6"
    style="
      background-image: url('https://images.sextermedia.com/content/images/background/0x0/sextermedia_background.webp');
    "
  >
    <div class="flex pt-4 pl-4">
      <img src="../assets/sextermedia-logo.png" />
    </div>
    <div class="flex justify-center items-center h-3/4">
      <div class="p-6 max-w-sm w-full bg-black shadow-md rounded-md">
        <div class="flex justify-center items-center">
          <span class="text-white font-semibold text-2xl">{{
            $t('verify_email.title')
          }}</span>
        </div>
        <div class="w-full mt-10 text-center text-lg">
          <p>{{ status_message }}</p>
        </div>
        <div class="w-full mt-10 text-center">
          <router-link :to="{ name: 'login' }">
            {{ $t('member_form.form.login_link_text') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import LoadingSpinner from '@/components/atoms/LoadingSpinner.vue';
import { registerService } from '@/services/Register.Service';
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'VerifyEmail',
  components: {
    LoadingSpinner,
  },
  setup() {
    const status_message = ref<string>()
    const route = useRoute()
    const loadingSpinner = ref(null)

    onMounted(async () => {
      loadingSpinner.value.open()
      await getVerifyEmail()
    })

    const getVerifyEmail = async () => {
      const verify_url = route.query.verify_url.toString()
      await registerService
        .verifyEmail(verify_url)
        .then((response) => {
          if (response.status === 200) {
            status_message.value = response.data['message']
            loadingSpinner.value.close()
          }
        })
        .catch((error_response) => {
          loadingSpinner.value.close()
        })
    }
    return {
      loadingSpinner,
      status_message,
    }
  },
})
</script>

<style scoped></style>
