<template>
  <div class="bg-cover h-screen px-6 bg-gradient-to-r from-black to-purple-500">
    <div class="flex pt-4 pl-4">
      <img src="../assets/sextermedia-logo.png" alt="logo" />
    </div>
    <div class="justify-center items-center h-3/4 max-w-6xl mx-auto">
      <div class="text-center my-10">
        <h1 class="font-bold text-2xl md:text-5xl mb-2 text-pink-500">
          {{ $t('price_table.header_title') }}
        </h1>
        <h4 class="text-white md:text-2xl">
          {{ $t('price_table.header_title_4') }}
        </h4>
      </div>
      <div class="flex flex-col md:flex-row px-2 md:px-0">
        <PriceTable
          :paymentPackage="paymentPackage"
          v-for="paymentPackage in paymentPackages"
          :key="paymentPackage.id"
          :count="paymentPackages.length"
        />
      </div>
      <div
        class="invisible md:visible bg-gray-800 text-white flex flex-row rounded-xl shadow hover:shadow-xl transition duration-100 ease-in-out mr-4 mt-10"
      >
        <div class="w-1/3 p-6 mr-4">
          <h2>{{ $t('payment_packages.our_promise') }}</h2>
          <div class="text-sm mt-4">
            <p class="my-2 flex">
              <IconCheck class="text-white" />
              {{ $t('price_table.no_hidden_costs') }}
            </p>
            <p class="my-2 flex">
              <IconCheck class="text-white" />
              {{ $t('price_table.cancellation') }}
            </p>
            <p class="my-2 flex">
              <IconCheck class="text-white" />
              {{ $t('price_table.no_cancellation_periods') }}
            </p>
            <p class="my-2 flex">
              <IconCheck class="text-white" />
              {{ $t('price_table.privacy') }}
            </p>
            <p class="my-2 flex">
              <IconCheck class="text-white" />
              {{ $t('price_table.bank_statement') }}
            </p>
          </div>
        </div>
        <div class="w-1/3 p-6 mr-4">
          <h2>{{ $t('payment_packages.our_guarantee') }}</h2>
          <div class="text-sm mt-4">
            <p class="my-2 flex">
              <IconCheck class="text-white" />
              {{ $t('price_table.streaming_function') }}
            </p>
            <p class="my-2 flex">
              <IconCheck class="text-white" />
              {{ $t('price_table.email_support') }}
            </p>
            <p class="my-2 flex">
              <IconCheck class="text-white" />
              {{ $t('price_table.full_access') }}
            </p>
            <p class="my-2 flex">
              <IconCheck class="text-white" />
              {{ $t('price_table.regular_updates') }}
            </p>
          </div>
        </div>
        <div class="w-1/3 p-6 mr-4">
          <h2>{{ $t('payment_packages.all_devices') }}</h2>
          <div class="text-sm mt-4">
            <p class="my-2 flex">
              <IconCheck class="text-white" />
              {{ $t('price_table.device_desktop') }}
            </p>
            <p class="my-2 flex">
              <IconCheck class="text-white" />
              {{ $t('price_table.device_laptop') }}
            </p>
            <p class="my-2 flex">
              <IconCheck class="text-white" />
              {{ $t('price_table.device_tablet') }}
            </p>
            <p class="my-2 flex">
              <IconCheck class="text-white" />
              {{ $t('price_table.device_smartphone') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { paymentPackagesService } from '@/services/PaymentPackages.Service'
import PaymentPackages from '@/types/PaymentPackages'
import PriceTable from '@/components/PriceTable.vue'
import IconCheck from '@/components/atoms/IconCheck.vue'

export default defineComponent({
  name: 'OfferPaymentPackages',
  components: { PriceTable, IconCheck },

  setup() {
    const paymentPackages = ref<PaymentPackages[]>()

    onMounted(async () => {
      await getPaymentPackagesData()
    })

    const getPaymentPackagesData = async () => {
      await paymentPackagesService
        .getOfferPaymentPackages()
        .then((response) => {
          paymentPackages.value = response.data.data
          console.log(paymentPackages.value)
        })
        .catch((error_response) => {
          console.log(error_response)
        })
    }

    return {
      paymentPackages,
    }
  },
})
</script>

<style scoped></style>
