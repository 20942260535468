<template>
  <div class="w-full h-full md:pb-44">
    <div class="w-full h-full">
      <Banner v-if="isDesktop" />
    </div>
    <div
      class="px-4 md:px-12 relative z-10 space-y-12"
      :class="[isDesktop ? '-mt-28' : 'mt-20']"
    >
      <VideoCarousel
        :items="videosData"
        :carouselName="$t('member_area.dashboard.new_videos')"
      />
      <VideoCarousel
        :items="topVideosData"
        :carouselName="$t('member_area.dashboard.top_videos')"
      />
      <VideoCarousel
        :items="topLikedVideos"
        :carouselName="$t('member_area.dashboard.top_liked_videos')"
      />
      <VideoCarousel
        :items="highlightsVideos"
        :carouselName="$t('member_area.dashboard.highlights_videos')"
      />
      <VideoCarousel
        :items="specialVideos"
        :carouselName="$t('member_area.dashboard.special_videos')"
      />
    </div>
  </div>
  <transition
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
  >
    <DetailsModal v-if="isModalActive" />
  </transition>
</template>
<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  onBeforeMount,
  watch,
  toRefs,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import VideoSet from '@/types/VideoSet'
import { videosSetsService } from '@/services/VideosSets.service'
import VideoCarousel from '@/components/VideoCarousel.vue'
import Banner from '@/components/Banner.vue'
import useDevice from '@/hooks/useDevice'
import { useStore } from 'vuex'
import DetailsModal from '@/components/DetailsModal.vue'
import { state } from '@/store/Modal'

export default defineComponent({
  name: 'Dashboard',
  components: {
    VideoCarousel,
    Banner,
    DetailsModal,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const videosData = ref<Array<VideoSet>>([])
    const bannerData = ref<Array<VideoSet>>([])
    const topVideosData = ref<Array<VideoSet>>([])
    const topLikedVideos = ref<Array<VideoSet>>([])
    const highlightsVideos = ref<Array<VideoSet>>([])
    const specialVideos = ref<Array<VideoSet>>([])
    const isDesktop = useDevice().isDesktop
    const userData = computed(() => store.state.auth.user)
    let userLanguage: string
    if (!userData.value.language.includes('-')) {
      userLanguage = userData.value.language
    } else {
      const languageSplitString = userData.value.language.split('-')
      userLanguage = languageSplitString[0]
    }
    const perPage = 24
    const page = route.query.page ?? 1
    onBeforeMount(async () => {
      await videosSetsService
        .getBannerVideo()
        .then((response) => {
          const userRole = userData.value.role
          if (userRole === 'user') {
            router.push({ name: 'pay_packages' })
          }
          bannerData.value = response.data.data
        })
        .catch((error) => {
          if (
            error.response.status === 403 &&
            error.response.statusText === 'Forbidden'
          ) {
            router.push({ name: 'forbidden' })
          }
        })
      await videosSetsService
        .getVideosSets(perPage, page, null)
        .then((response) => {
          videosData.value = response.data.data
        })
        .catch((error) => {
          if (
            error.response.status === 403 &&
            error.response.statusText === 'Forbidden'
          ) {
            router.push({ name: 'forbidden' })
          }
        })
      await videosSetsService
        .getTopVideo(perPage)
        .then((response) => {
          topVideosData.value = response.data.data
        })
        .catch((error) => {
          if (
            error.response.status === 403 &&
            error.response.statusText === 'Forbidden'
          ) {
            router.push({ name: 'forbidden' })
          }
        })

      await videosSetsService
        .getTopLiked(perPage)
        .then((response) => {
          topLikedVideos.value = response.data.data
        })
        .catch((error) => {
          if (
            error.response.status === 403 &&
            error.response.statusText === 'Forbidden'
          ) {
            router.push({ name: 'forbidden' })
          }
        })

      await videosSetsService
        .getHighlightsVideos(perPage)
        .then((response) => {
          highlightsVideos.value = response.data.data
        })
        .catch((error) => {
          if (
            error.response.status === 403 &&
            error.response.statusText === 'Forbidden'
          ) {
            router.push({ name: 'forbidden' })
          }
        })

      await videosSetsService
        .getSpecialVideos(perPage)
        .then((response) => {
          specialVideos.value = response.data.data
        })
        .catch((error) => {
          if (
            error.response.status === 403 &&
            error.response.statusText === 'Forbidden'
          ) {
            router.push({ name: 'forbidden' })
          }
        })
    })
    watch(
      () => state.isModalActive,
      () => {
        setTimeout(function () {
          window.scrollTo({
            left: 0,
            top: state.scrollTop,
          })
        }, 0)
      },
    )
    return {
      isDesktop,
      userLanguage,
      videosData,
      topVideosData,
      topLikedVideos,
      highlightsVideos,
      specialVideos,
      bannerData,
      ...toRefs(state),
    }
  },
})
</script>
