<template>
  <transition
    enter-active-class="animate-fadeIn"
    leave-active-class="animate-fadeOut"
  >
    <div
      class="fixed inset-0 top-16 z-0 bg-black bg-opacity-80"
      v-if="showMenu"
    ></div>
  </transition>
  <div v-bind="$attrs" class="relative z-10" @click="openNavMenu">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        d="M3 15h14v-2H3v2zM3 5v2h14V5H3zm0 6h14V9H3v2z"
      />
    </svg>
    <transition
      enter-active-class="animate-slideInLeft"
      leave-active-class="animate-slideInRight"
    >
      <div
        class="py-6 space-y-2 fixed left-0 top-16 h-screen bg-black"
        v-if="showMenu"
      >
        <router-link
          :to="{ name: 'dashboard' }"
          class="block text-base font-netflix_medium border-l-4 px-4 hover:text-pink-500"
          :class="[
            $route.name === 'dashboard'
              ? 'text-pink-500 border-primary'
              : 'text-gray-50 border-black',
          ]"
          >{{ $t('header.nav.dashboard') }}</router-link
        >

        <router-link
          :to="{ name: 'series' }"
          class="block text-base font-netflix_medium border-l-4 px-4 hover:text-pink-500"
          :class="[
            $route.name === 'series'
              ? 'text-pink-500 border-primary'
              : 'text-gray-50 border-black',
          ]"
          >{{ $t('header.nav.series') }}</router-link
        >
        <router-link
          :to="{ name: 'actors' }"
          class="block text-base font-netflix_medium border-l-4 px-4 hover:text-pink-500"
          :class="[
            $route.name === 'actors'
              ? 'text-pink-500 border-primary'
              : 'text-gray-50 border-black',
          ]"
          >{{ $t('header.nav.actors') }}</router-link
        >
        <router-link
          :to="{ name: 'user_favorites' }"
          class="block text-base font-netflix_medium border-l-4 px-4 hover:text-pink-500"
          :class="[
            $route.name === 'user_favorites'
              ? 'text-pink-500 border-primary'
              : 'text-gray-50 border-black',
          ]"
          >{{ $t('header.nav.favorites') }}</router-link
        >
        <a
          class="block text-base font-netflix_medium px-4 hover:text-pink-500 text-gray-50"
          href="#"
          @click.prevent="logout"
        >
          Log out
        </a>
      </div>
    </transition>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { mapActions } from 'vuex'
export default defineComponent({
  name: 'MobileNav',
  setup() {
    const showMenu = ref<boolean>(false)
    const openNavMenu = () => {
      showMenu.value = !showMenu.value
    }

    return {
      openNavMenu,
      showMenu,
    }
  },
  methods: {
    ...mapActions({
      logoutAction: 'auth/logoutAction',
    }),
    async logout() {
      await this.logoutAction().then(() => {
        this.$router.push({ name: 'login' })
      })
    },
    handleScroll(event) {
      this.isTop = event.target.scrollTop === 0
    },
  },
})
</script>
