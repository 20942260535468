import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_lazy = _resolveDirective("lazy")!

  return _withDirectives((_openBlock(), _createElementBlock("img", { src: _ctx.src }, null, 8, _hoisted_1)), [
    [_directive_lazy, _ctx.src]
  ])
}