<template>
  <div class="w-full h-full md:pb-44 gallery-container">
    <div class="px-4 xl:px-12 relative z-10 space-y-12 mt-20">
      <div class="w-60 h-full md:ml-10 md:mt-5">
        <button
          class="py-2 px-4 text-center bg-pink-700 rounded-md w-full text-white text-sm hover:bg-pink-800"
          @click="goBack"
        >
          {{ $t('video_player.back_button_text') }}
        </button>
      </div>
      <div class="space-y-2">
        <div class="xl:px-10">
          <div class="w-full mb-5">
            <h1 class="text-xl font-medium font-netflix_medium">{{ title }}</h1>
            <h2 class="mt-2">{{ $t('member_area.actors') }}</h2>
            <ul class="ml-5">
              <li
                class="cursor-pointer text-pink-500 hover:underline"
                v-for="actor in actors"
                :key="actor.id"
                @click="handleActorClick(actor.id)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="img"
                  width="1em"
                  height="1em"
                  class="text-xl float-left text-yellow-600 mr-1 mt-0.5"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 1024 1024"
                >
                  <path
                    fill="currentColor"
                    d="M923 283.6a260.04 260.04 0 0 0-56.9-82.8a264.4 264.4 0 0 0-84-55.5A265.34 265.34 0 0 0
                                       679.7 125c-49.3 0-97.4 13.5-139.2 39c-10 6.1-19.5 12.8-28.5 20.1c-9-7.3-18.5-14-28.5-20.1c-41.8-25.5-89.9-39-139.2-39c-35.5
                                       0-69.9 6.8-102.4 20.3c-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 0 0-56.9 82.8c-13.9 32.3-21 66.6-21 101.9c0 33.3
                                       6.8 68 20.3 103.3c11.3 29.5 27.5 60.1 48.2 91c32.8 48.9 77.9 99.9 133.9 151.6c92.8 85.7 184.7 144.9 188.6 147.3l23.7
                                       15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3c56-51.7 101.1-102.7 133.9-151.6c20.7-30.9 37-61.5
                                       48.2-91c13.5-35.3 20.3-70 20.3-103.3c.1-35.3-7-69.6-20.9-101.9z"
                  /></svg
                >{{ actor.alias }}
              </li>
            </ul>
            <h2 class="mt-2">{{ $t('member_area.description') }}</h2>
            <ul class="ml-5 mb-5">
              <li>
                {{ $t('member_area.series_si') }}:
                <router-link :to="{ path: '/series_images/' + series_id }">
                  {{ series_title }}
                </router-link>
              </li>
              <li>{{ $t('member_area.images_count') }}: {{ images_count }}</li>
            </ul>
            <p class="hidden md:block">
              {{ description }}
            </p>
          </div>
          <div class="flex flex-wrap items-center md:pl-10 lg:pl-0">
            <Image
              v-for="(image, key) in thumbs"
              :key="key"
              :src="image"
              class="relative transition-all duration-300 md:max-w-xs w-full my-3 xl:mx-3 mx-1 serie-card cursor-pointer"
              @click="handleImageClick(key)"
              @contextmenu="handleRightClick"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <transition
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
  >
    <Lightbox v-if="isLightBoxActive" :light-box-data="lightBoxData" />
  </transition>

  <transition
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
  >
    <ActorDetailsModal v-if="isActorsDetailModalActive" />
  </transition>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watch,
  toRefs,
} from 'vue'
import { galleryService } from '@/services/Gallery.Service'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Image from '@/components/atoms/Image.vue'
import Lightbox from '@/components/Lightbox.vue'
import ActorDetailsModal from '@/components/ActorDetailsModal.vue'
import { setLightBoxActive, setLightBoxData, state } from '@/store/LightBox'
import { state as actorModalState } from '@/store/ActorModal'
import LightBoxData from '@/types/LightBoxData'
import Actors from '@/types/Actors'
import {
  setActorsDetailModalActive,
  setIsVideoPlayer,
  setActorData,
  setIsNewGallery,
} from '@/store/ActorModal'

export default defineComponent({
  name: 'Gallery',
  components: { Image, Lightbox, ActorDetailsModal },
  setup() {
    const imagesSetId = ref<string | string[]>()
    const route = useRoute()
    const router = useRouter()
    imagesSetId.value = route.params.image_set_id
    const urlThumbs = ref<string>(null)
    const urlImage = ref<string>(null)
    const title = ref<string>(null)
    const description = ref<string>(null)
    const series_title = ref<string>(null)
    const series_id = ref<number>()
    const series_link = ref<string>(null)
    const user_lang = ref<string>(null)
    const thumbs = ref<string[]>([])
    const actors = ref<Actors[]>()
    const images_count = ref<number>(0)
    let images = []
    const lightBoxData: LightBoxData = { current: 0, images }

    const store = useStore()
    const userData = computed(() => store.state.auth.user)
    let userLanguage: string
    if (!userData.value.language.includes('-')) {
      userLanguage = userData.value.language
    } else {
      const languageSplitString = userData.value.language.split('-')
      userLanguage = languageSplitString[0]
    }
    user_lang.value = userLanguage

    window.scrollTo({ top: 65, left: 0, behavior: 'smooth' })

    onBeforeMount(async () => {
      await getGalleryData()
    })

    const getGalleryData = async () => {
      await galleryService
        .getImages(imagesSetId.value)
        .then((response) => {
          const data = response.data.data
          for (let key in data.images_sets_description) {
            if (data.images_sets_description[key].language === userLanguage) {
              title.value = data.images_sets_description[key].title
              description.value = data.images_sets_description[key].description
            }
          }
          urlThumbs.value = data.images_url + '/' + data.slug + '/340x0/'
          urlImage.value = data.images_url + '/' + data.slug + '/1920x0/'
          thumbs.value = data.content.map((item) => {
            return urlThumbs.value + item.image_name
          })
          lightBoxData.images = data.content.map((item) => {
            return urlImage.value + item.image_name
          })
          actors.value = data.actors
          images_count.value = data.images_count
          series_id.value = data.series.id
          for (let key in data.series.series_descriptions) {
            if (
              data.series.series_descriptions[key].language === userLanguage
            ) {
              series_title.value = data.series.series_descriptions[key].title
            }
          }
          series_link.value = '/series_images/' + data.series.id
        })
        .catch((error) => {
          if (
            error.response.status === 403 &&
            error.response.data.data.message === 'no_pay_user'
          ) {
            router.push({ name: 'pay_packages' })
          }
        })
    }

    const handleImageClick = (key: number) => {
      lightBoxData.current = key
      setLightBoxData(lightBoxData)
      setLightBoxActive(true)
    }

    const handleRightClick = (event) => {
      event.preventDefault()
    }

    watch(
      () => route.params,
      async (toParams, previousParams) => {
        if (
          toParams.image_set_id !== previousParams.image_set_id &&
          actorModalState.isNewGallery
        ) {
          setIsNewGallery(false)
          setActorsDetailModalActive(false)
          imagesSetId.value = toParams.image_set_id
          await getGalleryData()
        }
      },
    )

    watch(
      () => state.isLightBoxActive,
      () => {
        console.log('Lightbox')
      },
    )
    const goBack = () => {
      router.back()
    }
    const handleActorClick = (actorId: number) => {
      console.log('Clicked Actor Card ActorId: ' + actorId)
      setActorData(actorId)
      setActorsDetailModalActive(true)
      setIsVideoPlayer(true)
    }

    return {
      title,
      description,
      series_title,
      series_id,
      series_link,
      urlThumbs,
      urlImage,
      thumbs,
      actors,
      images_count,
      ...toRefs(actorModalState),
      ...toRefs(state),
      handleImageClick,
      handleRightClick,
      goBack,
      handleActorClick,
    }
  },
})
</script>

<style scoped></style>
