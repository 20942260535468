import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-677baa12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "transition-all duration-300 md:max-w-xs w-full my-3 xl:mx-3 mx-1 serie-card" }
const _hoisted_2 = { class: "bg-color items-center w-full cursor-pointer relative" }
const _hoisted_3 = { class: "p-4 w-full bg-color space-y-2 rounded-b-md" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "line-clamp-1" }
const _hoisted_6 = { class: "text-sm text-gray-400 line-clamp-6" }
const _hoisted_7 = { class: "flex justify-between space-x-2 text-xs content-center text-gray-400" }
const _hoisted_8 = { class: "text-bold" }
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = { class: "ml-4 pt-1" }
const _hoisted_11 = { class: "text-bold" }
const _hoisted_12 = { class: "pt-1" }
const _hoisted_13 = { class: "flex justify-between space-x-2 text-xs content-center mt-1 text-gray-400" }
const _hoisted_14 = { class: "justify-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_IconPlaySolid = _resolveComponent("IconPlaySolid")!
  const _component_IconThumbLike = _resolveComponent("IconThumbLike")!
  const _component_circle_button = _resolveComponent("circle-button")!
  const _component_IconThumbDislike = _resolveComponent("IconThumbDislike")!
  const _component_IconNoFavorite = _resolveComponent("IconNoFavorite")!
  const _component_IconIsFavorite = _resolveComponent("IconIsFavorite")!
  const _component_IconBxCalendar = _resolveComponent("IconBxCalendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Image, {
        src: 
          _ctx.videoSet.videos_sets_preview[0].video_set_preview_image +
          '/' +
          _ctx.videoSet.slug +
          '/340x227/' +
          _ctx.videoSet.videos_sets_preview[0].image_name
        ,
        onClick: _ctx.handlePlayClick
      }, null, 8, ["src", "onClick"]),
      _createElementVNode("div", {
        class: "transition-all absolute left-32 top-1/3 rounded-full p-4 bg-black bg-opacity-80 border border-gray-300 cursor-pointer",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlePlayClick && _ctx.handlePlayClick(...args)))
      }, [
        _createVNode(_component_IconPlaySolid, { class: "text-3xl" })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.description), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_circle_button, {
              class: "p-1",
              onClick: _ctx.setLike
            }, {
              default: _withCtx(() => [
                _createVNode(_component_IconThumbLike, { class: "text-3xl" })
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_circle_button, {
              class: "p-1 ml-3",
              onClick: _ctx.setDislike
            }, {
              default: _withCtx(() => [
                _createVNode(_component_IconThumbDislike, { class: "text-3xl" })
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_circle_button, {
              class: "p-1 ml-3",
              onClick: _ctx.setFavorites
            }, {
              default: _withCtx(() => [
                (!_ctx.isFavorite)
                  ? (_openBlock(), _createBlock(_component_IconNoFavorite, {
                      key: 0,
                      class: "text-3xl"
                    }))
                  : _createCommentVNode("", true),
                (_ctx.isFavorite)
                  ? (_openBlock(), _createBlock(_component_IconIsFavorite, {
                      key: 1,
                      class: "text-3xl"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.likes), 1)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_circle_button, {
            class: "p-1",
            onClick: _ctx.handlePlayClick
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconPlaySolid, { class: "text-3xl" })
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.duration) + " min", 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_IconBxCalendar, { class: "text-3xl" }),
          _createTextVNode(" " + _toDisplayString(_ctx.published), 1)
        ])
      ])
    ])
  ]))
}