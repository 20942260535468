<template>
  <div
    class="bg-cover h-screen px-6"
    style="
      background-image: url('https://images.sextermedia.com/content/images/background/0x0/sextermedia_background.webp');
    "
  >
    <div class="flex pt-4 pl-4">
      <img src="../assets/sextermedia-logo.png" />
    </div>
    <div class="flex justify-center items-center h-3/4">
      <div class="p-6 max-w-sm w-full bg-black shadow-md rounded-md">
        <div class="flex justify-center items-center">
          <span class="text-white font-semibold text-9xl text-gray-400">{{
            $t('forbidden_page.title')
          }}</span>
        </div>
        <div
          class="w-full mt-2 text-center items-center text-4xl text-gray-400"
        >
          <p>{{ $t('forbidden_page.forbidden') }}</p>
        </div>
        <div
          class="w-full mt-2 text-center items-center text-2xl text-gray-400"
        >
          {{ $t('forbidden_page.forbidden_body_no_email_verified') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ForbiddenPage',
})
</script>

<style scoped></style>
